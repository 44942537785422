<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="md:flex items-center flex-1">
              <div class="flex items-center w-full">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>

              <div class="sm:space-x-3 md:space-x-5 md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-bold hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;
                <span class="text-base font-medium text-yellow-500">></span>&nbsp;
                <router-link to="/incenti5" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;" >Incenti5</router-link>&nbsp;
                <span class="text-base font-bold text-yellow-500">Walkabout</span>&nbsp;
                <router-link to="/Bika" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Bika</router-link>&nbsp;
                <router-link to="/Tokaly" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Tokaly</router-link>&nbsp;
                <router-link to="/VExpoLab" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">VExpoLab</router-link>
              </div>

            </div>
            <!--<div class="hidden md:flex md:items-center md:space-x-6">
              <a href="#" class="text-base font-medium text-white hover:text-gray-300">
                Log in
              </a>
            </div>-->
          </nav>
        </div>

      <main>

        <div class="pt-10 bg-gray-800 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8" style="min-width: 0;">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8" >
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <h1 class="mt-3 text-3xl tracking-tight font-extrabold sm:mt-4 sm:text-5xl lg:mt-5 xl:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">
                    Walkabout.<!--is the Zulu word for “Report”.-->
                  </h1>
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                   Walkabout is a digital management tool that streamlines the monitoring, controlling and assigning of all functions and tasks within an organisation to ensure accountability and efficiency. </div>
                </div>
              </div>
              <div class="mt-12 mb-16 sm:mb-48 lg:m-0 lg:relative"  >
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 "  >

                  <!--<img id="gsapHeaderImage" class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/logos/bika.svg" alt="" />-->
                  <!-- VExpoLab_pnp_dairy_portrait.png -->
                  <!--Illustration.svg-->

                    <svg id="Walkabout" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 800 800">
                  <defs>
                    <linearGradient id="linear-gradient" x1="299.45" y1="122.38" x2="494.85" y2="122.38" gradientTransform="translate(789.61 805.91) rotate(-179.33)" gradientUnits="userSpaceOnUse">
                      <stop offset="0" stop-color="#fbbc3b"/>
                      <stop offset="1" stop-color="#f7931e"/>
                    </linearGradient>
                    <linearGradient id="linear-gradient-2" x1="299.45" y1="122.38" x2="494.85" y2="122.38" gradientTransform="translate(-8.1 793.11) rotate(-89.33)" xlink:href="#linear-gradient"/>
                    <linearGradient id="linear-gradient-3" x1="299.45" y1="122.38" x2="494.85" y2="122.38" gradientTransform="translate(4.7 -4.6) rotate(0.67)" xlink:href="#linear-gradient"/>
                    <linearGradient id="linear-gradient-4" x1="299.45" y1="122.38" x2="494.85" y2="122.38" gradientTransform="translate(802.41 8.2) rotate(90.67)" xlink:href="#linear-gradient"/>
                  </defs>
                  <g id="circles">
                    <circle cx="393.91" cy="678.91" r="97.7" fill="url(#linear-gradient)"/>
                    <circle cx="118.89" cy="397.41" r="97.7" fill="url(#linear-gradient-2)"/>
                    <circle cx="400.39" cy="122.4" r="97.7" fill="url(#linear-gradient-3)"/>
                    <circle cx="675.41" cy="403.9" r="97.7" fill="url(#linear-gradient-4)"/>
                  </g>
                  <g id="what">
                    <path d="M369.06,79.8l-5.94-24.26h4.72l2.3,11c.61,3,1.19,6,1.59,8.46h.07c.39-2.55,1.08-5.43,1.76-8.49l2.63-11h4.64l2.42,11.16c.61,2.88,1.11,5.58,1.47,8.2h.07c.44-2.66,1-5.43,1.66-8.38l2.52-11h4.5L387,79.8H382.2l-2.52-11.41a70.26,70.26,0,0,1-1.33-7.7h-.07c-.43,2.59-.9,4.93-1.62,7.7L373.85,79.8Z"/>
                    <path d="M396,54.24h4.47V64.68h.07a5.9,5.9,0,0,1,2.19-2,6,6,0,0,1,3-.8c3,0,6.12,2,6.12,7.6V79.8h-4.43V70c0-2.55-.94-4.46-3.39-4.46a3.62,3.62,0,0,0-3.59,3.82V79.8H396Z"/>
                    <path d="M426.45,79.8l-.29-1.94h-.11a6.54,6.54,0,0,1-5.18,2.34A5.21,5.21,0,0,1,415.36,75c0-4.43,3.92-6.66,10.4-6.63v-.28c0-1.16-.47-3.06-3.56-3.06a9.36,9.36,0,0,0-4.72,1.29l-.86-2.88A12.65,12.65,0,0,1,423,61.87c5.61,0,7.23,3.57,7.23,7.38v6.34a28.15,28.15,0,0,0,.25,4.21Zm-.58-8.57c-3.13-.07-6.12.61-6.12,3.28A2.32,2.32,0,0,0,422.27,77a3.59,3.59,0,0,0,3.46-2.41,3.51,3.51,0,0,0,.14-1Z"/>
                    <path d="M439.73,57.27v5h4.21v3.31h-4.21v7.74c0,2.12.58,3.24,2.27,3.24a6.51,6.51,0,0,0,1.73-.22l.07,3.39a9.82,9.82,0,0,1-3.21.43,5.12,5.12,0,0,1-3.81-1.44c-.94-1-1.37-2.59-1.37-4.9V65.58h-2.52V62.27h2.52v-4Z"/>
                    <path d="M339.2,110.69c0-2,0-3.71-.14-5.22H343l.21,2.63h.11a6.32,6.32,0,0,1,5.58-3c3.06,0,6.23,2,6.23,7.53V123h-4.43v-9.9c0-2.52-.94-4.43-3.35-4.43a3.7,3.7,0,0,0-3.45,2.6,4.31,4.31,0,0,0-.18,1.44V123H339.2Z"/>
                    <path d="M362.93,115.48c.1,3.16,2.59,4.53,5.4,4.53a13.21,13.21,0,0,0,4.85-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.68,0-9-3.49-9-8.86,0-4.85,2.95-9.43,8.57-9.43s7.55,4.68,7.55,8.53a11.33,11.33,0,0,1-.14,1.88Zm7.7-3.1c0-1.62-.69-4.28-3.64-4.28-2.73,0-3.88,2.48-4.06,4.28Z"/>
                    <path d="M381.5,115.48c.11,3.16,2.59,4.53,5.4,4.53a13.26,13.26,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.86,0-4.85,3-9.43,8.57-9.43s7.56,4.68,7.56,8.53a11.33,11.33,0,0,1-.14,1.88Zm7.7-3.1c0-1.62-.68-4.28-3.63-4.28-2.74,0-3.89,2.48-4.07,4.28Z"/>
                    <path d="M413.11,97.44v20.63c0,1.8.07,3.74.14,4.93h-4l-.18-2.77H409a6.32,6.32,0,0,1-5.72,3.17c-4.18,0-7.49-3.57-7.49-9,0-5.86,3.64-9.36,7.85-9.36a5.57,5.57,0,0,1,4.93,2.31h.07V97.44Zm-4.43,15.34a6.4,6.4,0,0,0-.11-1.15,3.88,3.88,0,0,0-3.81-3.14c-2.85,0-4.43,2.52-4.43,5.8s1.58,5.54,4.39,5.54a3.91,3.91,0,0,0,3.82-3.13,5.34,5.34,0,0,0,.14-1.3Z"/>
                    <path d="M417.65,118.93a9.84,9.84,0,0,0,4.49,1.26c1.95,0,2.81-.79,2.81-1.94s-.72-1.8-2.88-2.56c-3.42-1.19-4.86-3.06-4.82-5.11,0-3.09,2.55-5.51,6.62-5.51a10.3,10.3,0,0,1,4.65,1.05l-.87,3.13a7.88,7.88,0,0,0-3.71-1c-1.58,0-2.44.76-2.44,1.84s.83,1.65,3.06,2.45c3.16,1.15,4.64,2.77,4.68,5.36,0,3.17-2.49,5.47-7.13,5.47a11.35,11.35,0,0,1-5.33-1.22Z"/>
                    <path d="M445.4,100.47v5h4.21v3.31H445.4v7.74c0,2.12.58,3.24,2.27,3.24a6.36,6.36,0,0,0,1.72-.22l.08,3.39a9.82,9.82,0,0,1-3.21.43,5.12,5.12,0,0,1-3.81-1.44c-.94-1-1.37-2.59-1.37-4.9v-8.24h-2.52v-3.31h2.52v-4Z"/>
                    <path d="M469.41,114.07c0,6.45-4.54,9.33-9,9.33-5,0-8.78-3.42-8.78-9s3.78-9.29,9.07-9.29S469.41,108.75,469.41,114.07Zm-13.21.18c0,3.39,1.66,5.94,4.35,5.94,2.52,0,4.29-2.48,4.29-6,0-2.73-1.23-5.87-4.25-5.87S456.2,111.34,456.2,114.25Z"/>
                    <path d="M352.3,148.67c-.07,1.19-.14,2.63-.14,5v9.93c0,3.68-.76,6.3-2.59,8a10.1,10.1,0,0,1-6.77,2.12,12.47,12.47,0,0,1-6.12-1.4l1-3.35a10.59,10.59,0,0,0,5.12,1.33c2.88,0,5-1.51,5-5.29V163.5h-.07a5.89,5.89,0,0,1-5.11,2.59c-4.35,0-7.45-3.6-7.45-8.57,0-5.75,3.74-9.25,8-9.25A5.52,5.52,0,0,1,348.2,151h.07l.18-2.34Zm-4.57,7a4.22,4.22,0,0,0-.14-1.19,3.68,3.68,0,0,0-3.6-2.84c-2.48,0-4.32,2.16-4.32,5.72,0,3,1.51,5.4,4.28,5.4a3.77,3.77,0,0,0,3.6-2.66,7.52,7.52,0,0,0,.18-1.59Z"/>
                    <path d="M360.05,158.68c.1,3.16,2.59,4.53,5.4,4.53a13.21,13.21,0,0,0,4.85-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.68,0-9-3.49-9-8.86,0-4.85,2.95-9.43,8.57-9.43s7.55,4.68,7.55,8.53a11.33,11.33,0,0,1-.14,1.88Zm7.7-3.1c0-1.62-.69-4.28-3.64-4.28-2.73,0-3.88,2.48-4.06,4.28Z"/>
                    <path d="M380.57,143.67v5h4.21V152h-4.21v7.74c0,2.12.57,3.24,2.26,3.24a6.39,6.39,0,0,0,1.73-.22l.07,3.39a9.79,9.79,0,0,1-3.2.43,5.14,5.14,0,0,1-3.82-1.44c-.93-1-1.36-2.59-1.36-4.9V152h-2.52v-3.31h2.52v-4Z"/>
                    <path d="M411.74,140.64v20.63c0,1.8.07,3.74.14,4.93h-4l-.18-2.77h-.07A6.32,6.32,0,0,1,402,166.6c-4.18,0-7.49-3.57-7.49-9,0-5.86,3.64-9.36,7.85-9.36a5.57,5.57,0,0,1,4.93,2.31h.07v-9.94ZM407.31,156a5.6,5.6,0,0,0-.11-1.15,3.88,3.88,0,0,0-3.81-3.14c-2.85,0-4.43,2.52-4.43,5.8s1.58,5.54,4.39,5.54a3.9,3.9,0,0,0,3.82-3.13,4.89,4.89,0,0,0,.14-1.3Z"/>
                    <path d="M433.16,157.27c0,6.45-4.54,9.33-9,9.33-5,0-8.78-3.42-8.78-9s3.78-9.29,9.07-9.29S433.16,152,433.16,157.27Zm-13.21.18c0,3.39,1.65,5.94,4.35,5.94,2.52,0,4.29-2.48,4.29-6,0-2.73-1.23-5.87-4.25-5.87S420,154.54,420,157.45Z"/>
                    <path d="M436.8,153.89c0-2,0-3.71-.15-5.22h3.89l.22,2.63h.1a6.33,6.33,0,0,1,5.58-3c3.06,0,6.23,2,6.23,7.53v10.4h-4.43v-9.9c0-2.52-.93-4.43-3.34-4.43a3.71,3.71,0,0,0-3.46,2.6,4.55,4.55,0,0,0-.18,1.43v10.3H436.8Z"/>
                    <path d="M460.52,158.68c.11,3.16,2.59,4.53,5.4,4.53a13.26,13.26,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.86,0-4.85,3-9.43,8.57-9.43s7.56,4.68,7.56,8.53a10.13,10.13,0,0,1-.15,1.88Zm7.7-3.1c0-1.62-.68-4.28-3.63-4.28-2.74,0-3.89,2.48-4.07,4.28Z"/>
                  </g>
                  <g id="how">
                    <path d="M631.47,344.56v9.72h10.26v-9.72h4.43v24.26h-4.43V358.13H631.47v10.69H627V344.56Z"/>
                    <path d="M667.72,359.9c0,6.44-4.53,9.32-9,9.32-5,0-8.78-3.42-8.78-9s3.78-9.28,9.07-9.28S667.72,354.57,667.72,359.9Zm-13.21.17c0,3.39,1.66,5.94,4.36,5.94,2.52,0,4.28-2.48,4.28-6,0-2.73-1.23-5.86-4.25-5.86S654.51,357.16,654.51,360.07Z"/>
                    <path d="M673.73,351.29l1.84,8c.4,1.87.79,3.78,1.12,5.68h.07c.36-1.9.93-3.85,1.44-5.65l2.26-8h3.6l2.2,7.78c.54,2.05,1,4,1.4,5.86h.08c.28-1.9.68-3.81,1.15-5.86l1.94-7.78h4.36l-5.51,17.53h-4.1l-2.09-7.13a52,52,0,0,1-1.33-5.61h-.08a44.71,44.71,0,0,1-1.33,5.61l-2.23,7.13h-4.14l-5.18-17.53Z"/>
                    <path d="M710.2,346.4a2.49,2.49,0,0,1-5,0,2.49,2.49,0,0,1,5,0Zm-4.71,22.42V351.29H710v17.53Z"/>
                    <path d="M719.78,346.29v5H724v3.31h-4.21v7.74c0,2.13.57,3.24,2.26,3.24a6.9,6.9,0,0,0,1.73-.21l.07,3.38a9.75,9.75,0,0,1-3.2.43,5.1,5.1,0,0,1-3.81-1.44c-.94-1-1.37-2.59-1.37-4.89V354.6h-2.52v-3.31h2.52v-4Z"/>
                    <path d="M610.18,399.71c0-2,0-3.71-.15-5.22h3.89l.22,2.63h.11a6.3,6.3,0,0,1,5.57-3c3.06,0,6.23,2,6.23,7.52V412h-4.43v-9.9c0-2.52-.93-4.42-3.34-4.42a3.72,3.72,0,0,0-3.46,2.59,4.58,4.58,0,0,0-.18,1.44V412h-4.46Z"/>
                    <path d="M633.9,404.5c.11,3.17,2.59,4.53,5.4,4.53a13.06,13.06,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.85,0-4.86,3-9.43,8.57-9.43s7.56,4.68,7.56,8.53a10,10,0,0,1-.15,1.87Zm7.7-3.1c0-1.62-.68-4.28-3.63-4.28-2.74,0-3.89,2.48-4.07,4.28Z"/>
                    <path d="M652.48,404.5c.1,3.17,2.59,4.53,5.4,4.53a13.09,13.09,0,0,0,4.86-.79l.64,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.68,0-9-3.49-9-8.85,0-4.86,2.95-9.43,8.57-9.43s7.55,4.68,7.55,8.53a11.19,11.19,0,0,1-.14,1.87Zm7.7-3.1c0-1.62-.68-4.28-3.64-4.28-2.73,0-3.88,2.48-4.06,4.28Z"/>
                    <path d="M684.08,386.47v20.62c0,1.8.07,3.74.15,4.93h-4l-.18-2.77H680a6.3,6.3,0,0,1-5.72,3.17c-4.17,0-7.49-3.57-7.49-9,0-5.87,3.64-9.36,7.85-9.36a5.57,5.57,0,0,1,4.93,2.3h.08v-9.93Zm-4.42,15.33a6.4,6.4,0,0,0-.11-1.15,3.88,3.88,0,0,0-3.82-3.13c-2.84,0-4.43,2.52-4.43,5.79s1.59,5.54,4.4,5.54a3.89,3.89,0,0,0,3.81-3.13,4.76,4.76,0,0,0,.15-1.29Z"/>
                    <path d="M688.62,408a9.92,9.92,0,0,0,4.5,1.25c1.94,0,2.81-.79,2.81-1.94s-.72-1.8-2.88-2.56c-3.42-1.18-4.86-3-4.83-5.11,0-3.09,2.56-5.5,6.63-5.5a10.3,10.3,0,0,1,4.64,1l-.86,3.13a7.91,7.91,0,0,0-3.71-1c-1.59,0-2.45.76-2.45,1.84s.83,1.66,3.06,2.45c3.17,1.15,4.64,2.77,4.68,5.36,0,3.17-2.48,5.47-7.13,5.47a11.27,11.27,0,0,1-5.32-1.22Z"/>
                    <path d="M716.37,389.49v5h4.21v3.31h-4.21v7.74c0,2.13.58,3.24,2.27,3.24a7,7,0,0,0,1.73-.21l.07,3.38a9.79,9.79,0,0,1-3.2.43,5.14,5.14,0,0,1-3.82-1.44c-.93-1-1.37-2.59-1.37-4.89V397.8h-2.51v-3.31h2.51v-4Z"/>
                    <path d="M740.38,403.1c0,6.44-4.53,9.32-9,9.32-5,0-8.79-3.42-8.79-9s3.78-9.28,9.07-9.28S740.38,397.77,740.38,403.1Zm-13.21.18c0,3.38,1.66,5.93,4.36,5.93,2.52,0,4.28-2.48,4.28-6,0-2.73-1.22-5.86-4.24-5.86S727.17,400.36,727.17,403.28Z"/>
                    <path d="M613.22,455.22c.07-1.19.14-3.13.14-4.93V429.67h4.43V440.1h.07a6.35,6.35,0,0,1,5.62-2.8c4.28,0,7.34,3.56,7.31,8.89,0,6.3-4,9.43-8,9.43a6,6,0,0,1-5.54-3h-.08l-.21,2.63Zm4.57-7.23a5,5,0,0,0,.11,1,4.09,4.09,0,0,0,3.92,3.1c2.77,0,4.47-2.24,4.47-5.76,0-3.1-1.48-5.62-4.43-5.62a4.16,4.16,0,0,0-3.93,3.24,5.48,5.48,0,0,0-.14,1.15Z"/>
                    <path d="M637.55,447.7c.11,3.17,2.6,4.53,5.4,4.53a13,13,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.85,0-4.86,3-9.43,8.56-9.43s7.56,4.68,7.56,8.53a11.19,11.19,0,0,1-.14,1.87Zm7.71-3.1c0-1.62-.69-4.28-3.64-4.28-2.73,0-3.89,2.48-4.07,4.28Z"/>
                    <path d="M676.61,429.67v20.62c0,1.8.07,3.74.15,4.93h-4l-.18-2.77h-.08a6.3,6.3,0,0,1-5.72,3.17c-4.17,0-7.49-3.57-7.49-9,0-5.87,3.64-9.36,7.85-9.36a5.57,5.57,0,0,1,4.93,2.3h.07v-9.93ZM672.18,445a6.38,6.38,0,0,0-.1-1.15,3.88,3.88,0,0,0-3.82-3.13c-2.84,0-4.43,2.52-4.43,5.79s1.59,5.54,4.4,5.54a3.89,3.89,0,0,0,3.81-3.13,4.82,4.82,0,0,0,.14-1.29Z"/>
                    <path d="M698,446.29c0,6.45-4.53,9.33-9,9.33-5,0-8.78-3.42-8.78-9s3.78-9.28,9.07-9.28S698,441,698,446.29Zm-13.21.18c0,3.39,1.66,5.94,4.36,5.94,2.52,0,4.28-2.48,4.28-6,0-2.73-1.22-5.86-4.25-5.86S684.82,443.56,684.82,446.47Z"/>
                    <path d="M701.67,442.91c0-2,0-3.71-.14-5.22h3.88l.22,2.63h.11a6.3,6.3,0,0,1,5.58-3c3.06,0,6.22,2,6.22,7.52v10.4h-4.42v-9.9c0-2.52-.94-4.42-3.35-4.42a3.72,3.72,0,0,0-3.46,2.59,4.58,4.58,0,0,0-.18,1.44v10.29h-4.46Z"/>
                    <path d="M725.39,447.7c.11,3.17,2.59,4.53,5.4,4.53a13.06,13.06,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.85,0-4.86,3-9.43,8.56-9.43s7.56,4.68,7.56,8.53a11.19,11.19,0,0,1-.14,1.87Zm7.71-3.1c0-1.62-.69-4.28-3.64-4.28-2.74,0-3.89,2.48-4.07,4.28Z"/>
                  </g>
                  <g id="howwell">
                    <path d="M329.84,650.43v9.72H340.1v-9.72h4.43v24.26H340.1V664H329.84v10.69h-4.42V650.43Z"/>
                    <path d="M366.09,665.76c0,6.45-4.53,9.32-9,9.32-5,0-8.79-3.41-8.79-9s3.78-9.29,9.07-9.29S366.09,660.43,366.09,665.76Zm-13.21.18c0,3.39,1.66,5.94,4.36,5.94,2.52,0,4.28-2.48,4.28-6,0-2.74-1.22-5.87-4.24-5.87S352.88,663,352.88,665.94Z"/>
                    <path d="M372.11,657.16l1.83,8c.4,1.88.8,3.78,1.12,5.69h.07c.36-1.91.94-3.85,1.44-5.65l2.27-8h3.6l2.2,7.77c.54,2.06,1,4,1.4,5.87h.07c.29-1.91.68-3.81,1.15-5.87l1.95-7.77h4.35l-5.51,17.53H384l-2.09-7.13a54.53,54.53,0,0,1-1.33-5.61h-.07a47,47,0,0,1-1.33,5.61l-2.23,7.13h-4.14l-5.19-17.53Z"/>
                    <path d="M406.52,657.16l1.84,8c.39,1.88.79,3.78,1.12,5.69h.07c.36-1.91.93-3.85,1.44-5.65l2.26-8h3.6l2.2,7.77c.54,2.06,1,4,1.4,5.87h.08c.28-1.91.68-3.81,1.15-5.87l1.94-7.77H428l-5.51,17.53h-4.1l-2.09-7.13A52,52,0,0,1,415,662h-.08a45.13,45.13,0,0,1-1.33,5.61l-2.23,7.13h-4.14L402,657.16Z"/>
                    <path d="M433.49,667.17c.11,3.16,2.59,4.53,5.4,4.53a13.26,13.26,0,0,0,4.86-.79l.65,3.06a16.58,16.58,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.86,0-4.86,2.95-9.43,8.57-9.43s7.56,4.68,7.56,8.53a11.1,11.1,0,0,1-.15,1.88Zm7.7-3.1c0-1.62-.68-4.28-3.63-4.28-2.74,0-3.89,2.48-4.07,4.28Z"/>
                    <path d="M448.93,649.13h4.47v25.56h-4.47Z"/>
                    <path d="M458.18,649.13h4.47v25.56h-4.47Z"/>
                    <path d="M330.37,695.46a2.49,2.49,0,1,1-2.49-2.41A2.37,2.37,0,0,1,330.37,695.46Zm-4.72,22.43V700.36h4.47v17.53Z"/>
                    <path d="M339.94,695.36v5h4.21v3.31h-4.21v7.74c0,2.12.58,3.24,2.27,3.24a6.45,6.45,0,0,0,1.73-.22l.07,3.39a9.79,9.79,0,0,1-3.2.43,5.14,5.14,0,0,1-3.82-1.44c-.93-1-1.37-2.59-1.37-4.9v-8.24H333.1v-3.31h2.52v-4Z"/>
                    <path d="M359.67,695.46a2.49,2.49,0,0,1-5,0,2.49,2.49,0,0,1,5,0ZM355,717.89V700.36h4.47v17.53Z"/>
                    <path d="M364,713.82a9.92,9.92,0,0,0,4.5,1.26c1.95,0,2.81-.79,2.81-1.94s-.72-1.8-2.88-2.56c-3.42-1.19-4.86-3.06-4.82-5.11,0-3.09,2.55-5.51,6.62-5.51a10.3,10.3,0,0,1,4.65,1l-.87,3.13a7.88,7.88,0,0,0-3.71-1c-1.58,0-2.44.76-2.44,1.84s.82,1.65,3.06,2.44c3.16,1.16,4.64,2.78,4.68,5.37,0,3.17-2.49,5.47-7.13,5.47a11.31,11.31,0,0,1-5.33-1.22Z"/>
                    <path d="M402.8,692.33V713c0,1.8.07,3.74.14,4.93h-4l-.18-2.77h-.07a6.33,6.33,0,0,1-5.72,3.17c-4.18,0-7.49-3.57-7.49-9,0-5.86,3.63-9.36,7.85-9.36a5.59,5.59,0,0,1,4.93,2.31h.07v-9.94Zm-4.43,15.34a6.51,6.51,0,0,0-.11-1.16,3.89,3.89,0,0,0-3.82-3.13c-2.84,0-4.42,2.52-4.42,5.8s1.58,5.54,4.39,5.54a3.89,3.89,0,0,0,3.81-3.13,4.88,4.88,0,0,0,.15-1.3Z"/>
                    <path d="M424.21,709c0,6.45-4.53,9.33-9,9.33-5,0-8.79-3.42-8.79-9s3.78-9.29,9.07-9.29S424.21,703.63,424.21,709Zm-13.21.18c0,3.39,1.66,5.94,4.36,5.94,2.52,0,4.28-2.48,4.28-6,0-2.74-1.22-5.87-4.24-5.87S411,706.23,411,709.14Z"/>
                    <path d="M427.85,705.58c0-2,0-3.71-.14-5.22h3.89l.21,2.63h.11a6.32,6.32,0,0,1,5.58-3c3.06,0,6.23,2,6.23,7.53v10.4H439.3V708c0-2.52-.94-4.43-3.35-4.43a3.7,3.7,0,0,0-3.45,2.59,4.38,4.38,0,0,0-.18,1.44v10.3h-4.47Z"/>
                    <path d="M451.58,710.37c.1,3.16,2.59,4.53,5.4,4.53a13.21,13.21,0,0,0,4.85-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.68,0-9-3.49-9-8.86,0-4.86,2.95-9.43,8.57-9.43s7.56,4.68,7.56,8.53a11.1,11.1,0,0,1-.15,1.88Zm7.7-3.1c0-1.62-.68-4.28-3.64-4.28-2.73,0-3.88,2.48-4.06,4.28Z"/>
                  </g>
                  <g id="impact">
                    <path d="M37,388.67H30.09V385H48.41v3.71h-7v20.55H37Z"/>
                    <path d="M50.72,383.66h4.46V394.1h.08a6.37,6.37,0,0,1,5.21-2.81c3,0,6.12,2,6.12,7.6v10.33H62.17v-9.83c0-2.55-.94-4.46-3.39-4.46a3.62,3.62,0,0,0-3.42,2.48,3.85,3.85,0,0,0-.18,1.34v10.47H50.72Z"/>
                    <path d="M74.44,401.7c.11,3.17,2.6,4.53,5.4,4.53a13.06,13.06,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.49-9-8.85,0-4.86,3-9.44,8.56-9.44s7.56,4.68,7.56,8.54a11.29,11.29,0,0,1-.14,1.87Zm7.71-3.1c0-1.62-.69-4.28-3.64-4.28-2.73,0-3.89,2.48-4.07,4.28Z"/>
                    <path d="M102.05,386.8a2.48,2.48,0,0,1-5,0,2.48,2.48,0,0,1,5,0Zm-4.71,22.42V391.69h4.46v17.53Z"/>
                    <path d="M106.55,396.91c0-2,0-3.71-.14-5.22h3.82l.18,2.59h.1a6.07,6.07,0,0,1,5.4-3,5,5,0,0,1,4.86,3.24h.08a6.63,6.63,0,0,1,2.15-2.19,5.84,5.84,0,0,1,3.53-1c2.92,0,5.87,2,5.87,7.6v10.33h-4.32v-9.68c0-2.92-1-4.65-3.13-4.65a3.39,3.39,0,0,0-3.1,2.34,7,7,0,0,0-.21,1.48v10.51h-4.32V399.07c0-2.45-1-4.18-3-4.18a3.45,3.45,0,0,0-3.16,2.52,3.84,3.84,0,0,0-.26,1.44v10.37h-4.32Z"/>
                    <path d="M137.08,397.52c0-2.3-.07-4.17-.14-5.83h3.89l.21,2.7h.07a6.84,6.84,0,0,1,6-3.1c4,0,7.37,3.46,7.37,8.93,0,6.34-4,9.4-8,9.4a5.77,5.77,0,0,1-4.93-2.34h-.07v9.07h-4.43Zm4.43,4.36a4.86,4.86,0,0,0,.14,1.22,4,4,0,0,0,3.89,3.1c2.81,0,4.46-2.34,4.46-5.8,0-3.09-1.51-5.61-4.35-5.61a4.12,4.12,0,0,0-4,3.27,4,4,0,0,0-.18,1.12Z"/>
                    <path d="M168,409.22l-.29-1.94h-.11a6.54,6.54,0,0,1-5.18,2.34,5.21,5.21,0,0,1-5.51-5.22c0-4.43,3.92-6.66,10.4-6.63v-.28c0-1.16-.47-3.06-3.56-3.06a9.36,9.36,0,0,0-4.72,1.29l-.86-2.88a12.63,12.63,0,0,1,6.33-1.55c5.62,0,7.24,3.57,7.24,7.38V405a28.15,28.15,0,0,0,.25,4.21Zm-.58-8.57c-3.13-.07-6.12.62-6.12,3.28a2.32,2.32,0,0,0,2.52,2.52,3.6,3.6,0,0,0,3.46-2.41,3.5,3.5,0,0,0,.14-1Z"/>
                    <path d="M189.32,408.68a12.87,12.87,0,0,1-5,.9c-5.47,0-9-3.49-9-8.93,0-5.25,3.6-9.36,9.75-9.36a11,11,0,0,1,4.28.83l-.79,3.31a7.86,7.86,0,0,0-3.35-.68c-3.38,0-5.39,2.48-5.36,5.69,0,3.6,2.34,5.65,5.36,5.65a8.73,8.73,0,0,0,3.5-.68Z"/>
                    <path d="M198.21,386.69v5h4.21V395h-4.21v7.74c0,2.13.58,3.24,2.27,3.24a7,7,0,0,0,1.73-.21l.07,3.38a9.82,9.82,0,0,1-3.21.43,5.12,5.12,0,0,1-3.81-1.44c-.94-1-1.37-2.59-1.37-4.89V395h-2.52v-3.31h2.52v-4Z"/>
                  </g>
                  <g id="reports">
                    <path d="M198.67,254.16a22.19,22.19,0,0,1,4-.33c2.23,0,3.67.4,4.68,1.31a4.06,4.06,0,0,1,1.27,3.07,4.26,4.26,0,0,1-3.05,4.13v.07c1.25.43,2,1.58,2.38,3.26a25.65,25.65,0,0,0,1.24,4.43H207a20.75,20.75,0,0,1-1.07-3.86c-.48-2.23-1.35-3.07-3.24-3.14h-2v7h-2.08Zm2.08,7.36h2.14c2.23,0,3.64-1.22,3.64-3.07,0-2.08-1.51-3-3.71-3a9,9,0,0,0-2.07.19Z" fill="#bdccd4"/>
                    <path d="M212.76,264.68a3.72,3.72,0,0,0,4,4,7.63,7.63,0,0,0,3.22-.6l.35,1.51a9.28,9.28,0,0,1-3.85.72c-3.58,0-5.71-2.35-5.71-5.85s2.06-6.25,5.44-6.25c3.79,0,4.8,3.33,4.8,5.46a6.57,6.57,0,0,1-.08,1Zm6.19-1.51c0-1.34-.55-3.42-2.93-3.42-2.13,0-3.06,2-3.23,3.42Z" fill="#bdccd4"/>
                    <path d="M223.6,262.29c0-1.49,0-2.69-.1-3.79h1.9l.09,2h0a4.56,4.56,0,0,1,4.12-2.25c2.81,0,4.92,2.37,4.92,5.89,0,4.17-2.54,6.24-5.28,6.24a4.08,4.08,0,0,1-3.57-1.83h0v6.31H223.6Zm2.08,3.09a4.51,4.51,0,0,0,.1.86,3.24,3.24,0,0,0,3.16,2.47c2.23,0,3.53-1.82,3.53-4.48,0-2.33-1.22-4.32-3.45-4.32a3.36,3.36,0,0,0-3.19,2.62,3.44,3.44,0,0,0-.15.86Z" fill="#bdccd4"/>
                    <path d="M247.74,264.21c0,4.29-3,6.16-5.78,6.16-3.14,0-5.56-2.31-5.56-6,0-3.89,2.54-6.16,5.75-6.16S247.74,260.66,247.74,264.21Zm-9.21.11c0,2.55,1.46,4.46,3.53,4.46s3.52-1.89,3.52-4.5c0-2-1-4.46-3.48-4.46S238.53,262.12,238.53,264.32Z" fill="#bdccd4"/>
                    <path d="M250.4,262.12c0-1.37,0-2.54-.1-3.62h1.85l.07,2.28h.09a3.48,3.48,0,0,1,3.22-2.54,2.25,2.25,0,0,1,.6.07v2a3.08,3.08,0,0,0-.72-.07,3,3,0,0,0-2.83,2.7,5.81,5.81,0,0,0-.1,1v6.18H250.4Z" fill="#bdccd4"/>
                    <path d="M261.35,255.17v3.33h3v1.61h-3v6.25c0,1.44.41,2.26,1.58,2.26a4.77,4.77,0,0,0,1.23-.15l.09,1.58a5.06,5.06,0,0,1-1.87.29,2.89,2.89,0,0,1-2.27-.89,4.28,4.28,0,0,1-.82-3v-6.32h-1.8V258.5h1.8v-2.78Z" fill="#bdccd4"/>
                    <path d="M266.46,267.94a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.06-1.89-3.06-3.28,0-1.87,1.51-3.4,4-3.4a5.73,5.73,0,0,1,2.85.72l-.53,1.53a4.46,4.46,0,0,0-2.37-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.2,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                  </g>
                  <g id="duties">
                    <path d="M477,254.16a30.14,30.14,0,0,1,4.43-.33c3,0,5.13.69,6.55,2a7.44,7.44,0,0,1,2.27,5.8,8.54,8.54,0,0,1-2.32,6.28c-1.51,1.51-4,2.33-7.14,2.33a33.35,33.35,0,0,1-3.79-.2Zm2.08,14.29a14,14,0,0,0,2.11.12c4.46,0,6.88-2.5,6.88-6.86,0-3.81-2.13-6.23-6.54-6.23a11.82,11.82,0,0,0-2.45.22Z" fill="#bdccd4"/>
                    <path d="M502.64,266.94c0,1.2,0,2.25.1,3.16h-1.87l-.12-1.89h-.05a4.39,4.39,0,0,1-3.84,2.16c-1.82,0-4-1-4-5.09V258.5H495v6.42c0,2.21.67,3.7,2.59,3.7a3.06,3.06,0,0,0,2.78-1.92,3.1,3.1,0,0,0,.19-1.08V258.5h2.11Z" fill="#bdccd4"/>
                    <path d="M508.68,255.17v3.33h3v1.61h-3v6.25c0,1.44.41,2.26,1.58,2.26a4.84,4.84,0,0,0,1.23-.15l.09,1.58a5.1,5.1,0,0,1-1.87.29,2.93,2.93,0,0,1-2.28-.89,4.33,4.33,0,0,1-.81-3v-6.32h-1.8V258.5h1.8v-2.78Z" fill="#bdccd4"/>
                    <path d="M516.42,255.24a1.24,1.24,0,0,1-1.34,1.29,1.25,1.25,0,0,1-1.27-1.29,1.29,1.29,0,0,1,1.32-1.32A1.25,1.25,0,0,1,516.42,255.24Zm-2.34,14.86V258.5h2.1v11.6Z" fill="#bdccd4"/>
                    <path d="M520.86,264.68a3.72,3.72,0,0,0,4,4,7.54,7.54,0,0,0,3.21-.6l.36,1.51a9.3,9.3,0,0,1-3.86.72c-3.57,0-5.7-2.35-5.7-5.85s2.06-6.25,5.44-6.25c3.78,0,4.79,3.33,4.79,5.46a8.79,8.79,0,0,1-.07,1Zm6.18-1.51c0-1.34-.55-3.42-2.92-3.42-2.13,0-3.07,2-3.24,3.42Z" fill="#bdccd4"/>
                    <path d="M531.41,267.94a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.73,5.73,0,0,1,2.85.72l-.53,1.53a4.46,4.46,0,0,0-2.37-.67c-1.25,0-1.94.72-1.94,1.58s.69,1.39,2.2,2c2,.76,3.05,1.77,3.05,3.5,0,2-1.58,3.47-4.34,3.47a6.6,6.6,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M552.69,270.1l-.17-1.46h-.07a4.33,4.33,0,0,1-3.55,1.73,3.31,3.31,0,0,1-3.54-3.34c0-2.8,2.49-4.34,7-4.31v-.24a2.39,2.39,0,0,0-2.63-2.69,5.81,5.81,0,0,0-3,.87l-.47-1.39a7.09,7.09,0,0,1,3.81-1c3.54,0,4.41,2.42,4.41,4.74v4.34a16.71,16.71,0,0,0,.19,2.78Zm-.31-5.92c-2.3-.05-4.91.36-4.91,2.61a1.87,1.87,0,0,0,2,2,2.89,2.89,0,0,0,2.8-1.94,2.32,2.32,0,0,0,.12-.68Z" fill="#bdccd4"/>
                    <path d="M557.82,261.64c0-1.2,0-2.18-.09-3.14h1.87l.12,1.92h0a4.26,4.26,0,0,1,3.84-2.18c1.61,0,4.1.95,4.1,4.93v6.93h-2.11v-6.69c0-1.87-.7-3.42-2.69-3.42a3.11,3.11,0,0,0-3,3.14v7h-2.11Z" fill="#bdccd4"/>
                    <path d="M581.15,253.08v14c0,1,0,2.2.09,3h-1.89l-.1-2h0a4.29,4.29,0,0,1-4,2.28c-2.81,0-5-2.38-5-5.9,0-3.86,2.37-6.23,5.2-6.23A3.9,3.9,0,0,1,579,260h0v-6.93ZM579,263.22a3.77,3.77,0,0,0-.1-.88,3.09,3.09,0,0,0-3-2.45c-2.19,0-3.48,1.92-3.48,4.48,0,2.35,1.15,4.29,3.43,4.29a3.16,3.16,0,0,0,3.09-2.51,3.79,3.79,0,0,0,.1-.91Z" fill="#bdccd4"/>
                    <path d="M591.88,255.72H587V254h12v1.77H594V270.1h-2.11Z" fill="#bdccd4"/>
                    <path d="M605.55,270.1l-.17-1.46h-.07a4.33,4.33,0,0,1-3.55,1.73,3.31,3.31,0,0,1-3.55-3.34c0-2.8,2.5-4.34,7-4.31v-.24a2.4,2.4,0,0,0-2.64-2.69,5.77,5.77,0,0,0-3,.87l-.48-1.39a7.14,7.14,0,0,1,3.81-1c3.55,0,4.41,2.42,4.41,4.74v4.34a15.64,15.64,0,0,0,.2,2.78Zm-.31-5.92c-2.3-.05-4.92.36-4.92,2.61a1.87,1.87,0,0,0,2,2,2.9,2.9,0,0,0,2.81-1.94,2.32,2.32,0,0,0,.12-.68Z" fill="#bdccd4"/>
                    <path d="M610.39,267.94a5.51,5.51,0,0,0,2.78.84c1.54,0,2.25-.76,2.25-1.72s-.59-1.56-2.15-2.14c-2.09-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.67,5.67,0,0,1,2.85.72l-.52,1.53a4.53,4.53,0,0,0-2.38-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.21,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M622.26,263.82h0c.29-.41.7-.91,1-1.32l3.41-4h2.54l-4.48,4.77,5.1,6.83h-2.56l-4-5.56-1.07,1.2v4.36h-2.09v-17h2.09Z" fill="#bdccd4"/>
                    <path d="M631.12,267.94a5.55,5.55,0,0,0,2.79.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.73,5.73,0,0,1,2.85.72l-.53,1.53a4.46,4.46,0,0,0-2.37-.67c-1.25,0-1.94.72-1.94,1.58s.69,1.39,2.2,2c2,.76,3.05,1.77,3.05,3.5,0,2-1.59,3.47-4.34,3.47a6.6,6.6,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                  </g>
                  <g id="jobcards">
                    <path d="M485.56,525.4h2.09v10.82c0,4.29-2.11,5.61-4.89,5.61a6.64,6.64,0,0,1-2.23-.39l.31-1.7a4.94,4.94,0,0,0,1.75.31c1.87,0,3-.84,3-4Z" fill="#bdccd4"/>
                    <path d="M501.55,535.66c0,4.3-3,6.17-5.77,6.17-3.15,0-5.57-2.31-5.57-6,0-3.89,2.54-6.16,5.76-6.16S501.55,532.12,501.55,535.66Zm-9.2.12c0,2.55,1.46,4.46,3.52,4.46s3.52-1.89,3.52-4.5c0-2-1-4.46-3.47-4.46S492.35,533.58,492.35,535.78Z" fill="#bdccd4"/>
                    <path d="M504.12,541.56c.05-.79.09-2,.09-3v-14h2.09v7.29h.05a4.3,4.3,0,0,1,3.95-2.13c2.88,0,4.92,2.39,4.89,5.92,0,4.14-2.61,6.21-5.2,6.21a4.12,4.12,0,0,1-3.88-2.19H506l-.1,1.92Zm2.18-4.65a4.77,4.77,0,0,0,.09.77,3.26,3.26,0,0,0,3.17,2.47c2.2,0,3.52-1.8,3.52-4.46,0-2.33-1.2-4.32-3.45-4.32a3.37,3.37,0,0,0-3.21,2.59,4.06,4.06,0,0,0-.12.87Z" fill="#bdccd4"/>
                    <path d="M531.2,541.13a7.72,7.72,0,0,1-3.33.67c-3.5,0-5.78-2.37-5.78-5.92a5.9,5.9,0,0,1,6.24-6.16,7,7,0,0,1,2.92.6l-.48,1.63a4.84,4.84,0,0,0-2.44-.55c-2.66,0-4.1,2-4.1,4.38,0,2.69,1.72,4.34,4,4.34a6,6,0,0,0,2.58-.57Z" fill="#bdccd4"/>
                    <path d="M540.1,541.56l-.17-1.46h-.07a4.34,4.34,0,0,1-3.55,1.73,3.31,3.31,0,0,1-3.55-3.34c0-2.8,2.49-4.34,7-4.31v-.24a2.4,2.4,0,0,0-2.64-2.69,5.77,5.77,0,0,0-3,.87l-.48-1.39a7.14,7.14,0,0,1,3.81-1c3.55,0,4.41,2.42,4.41,4.74v4.34a16.71,16.71,0,0,0,.19,2.78Zm-.31-5.92c-2.31,0-4.92.36-4.92,2.61a1.87,1.87,0,0,0,2,2,2.9,2.9,0,0,0,2.81-1.94,2.32,2.32,0,0,0,.12-.68Z" fill="#bdccd4"/>
                    <path d="M545.23,533.58c0-1.37,0-2.54-.1-3.62H547l.07,2.28h.09a3.48,3.48,0,0,1,3.22-2.54,2.25,2.25,0,0,1,.6.07v2a3,3,0,0,0-.72-.08,3,3,0,0,0-2.83,2.71,5.81,5.81,0,0,0-.1,1v6.18h-2.08Z" fill="#bdccd4"/>
                    <path d="M562.85,524.54v14c0,1,0,2.21.09,3h-1.89l-.1-2h-.05a4.29,4.29,0,0,1-3.95,2.28c-2.81,0-5-2.38-5-5.9,0-3.86,2.37-6.23,5.2-6.23a3.87,3.87,0,0,1,3.5,1.77h0v-6.93Zm-2.11,10.14a3.92,3.92,0,0,0-.1-.89,3.1,3.1,0,0,0-3-2.44c-2.18,0-3.48,1.92-3.48,4.48,0,2.35,1.15,4.29,3.43,4.29a3.16,3.16,0,0,0,3.09-2.51,3.79,3.79,0,0,0,.1-.91Z" fill="#bdccd4"/>
                    <path d="M566.06,539.4a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.06-1.89-3.06-3.28,0-1.87,1.51-3.4,4-3.4a5.85,5.85,0,0,1,2.85.71l-.53,1.54a4.46,4.46,0,0,0-2.37-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.2,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M579.15,542.52l6.68-17.38h1.63l-6.71,17.38Z" fill="#bdccd4"/>
                    <path d="M594.29,525.6a23.9,23.9,0,0,1,4-.32,6.6,6.6,0,0,1,4.53,1.35,4.32,4.32,0,0,1,1.41,3.38,4.77,4.77,0,0,1-1.24,3.45,6.72,6.72,0,0,1-4.94,1.77,7.17,7.17,0,0,1-1.68-.14v6.47h-2.09Zm2.09,7.79a7.06,7.06,0,0,0,1.73.16c2.51,0,4-1.22,4-3.45s-1.51-3.16-3.81-3.16a8.47,8.47,0,0,0-2,.17Z" fill="#bdccd4"/>
                    <path d="M606.59,533.58c0-1.37,0-2.54-.09-3.62h1.84l.07,2.28h.1a3.48,3.48,0,0,1,3.21-2.54,2.2,2.2,0,0,1,.6.07v2a2.88,2.88,0,0,0-3.55,2.63,7.12,7.12,0,0,0-.09,1v6.18h-2.09Z" fill="#bdccd4"/>
                    <path d="M624.69,535.66c0,4.3-3,6.17-5.78,6.17-3.14,0-5.56-2.31-5.56-6,0-3.89,2.54-6.16,5.76-6.16S624.69,532.12,624.69,535.66Zm-9.2.12c0,2.55,1.46,4.46,3.52,4.46s3.52-1.89,3.52-4.5c0-2-1-4.46-3.47-4.46S615.49,533.58,615.49,535.78Z" fill="#bdccd4"/>
                    <path d="M635.62,541.13a7.72,7.72,0,0,1-3.33.67c-3.5,0-5.78-2.37-5.78-5.92a5.9,5.9,0,0,1,6.24-6.16,7,7,0,0,1,2.92.6l-.48,1.63a4.84,4.84,0,0,0-2.44-.55c-2.67,0-4.1,2-4.1,4.38,0,2.69,1.72,4.34,4,4.34a6.06,6.06,0,0,0,2.59-.57Z" fill="#bdccd4"/>
                    <path d="M639.12,536.14a3.72,3.72,0,0,0,4,4,7.54,7.54,0,0,0,3.21-.6l.36,1.51a9.3,9.3,0,0,1-3.86.72c-3.57,0-5.7-2.35-5.7-5.85s2.06-6.25,5.44-6.25c3.79,0,4.79,3.33,4.79,5.46a6.87,6.87,0,0,1-.07,1Zm6.19-1.51c0-1.34-.56-3.42-2.93-3.42-2.13,0-3.07,2-3.23,3.42Z" fill="#bdccd4"/>
                    <path d="M649.67,539.4a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.06-1.89-3.06-3.28,0-1.87,1.51-3.4,4-3.4a5.82,5.82,0,0,1,2.85.71l-.52,1.54a4.53,4.53,0,0,0-2.38-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.21,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M659.16,539.4a5.51,5.51,0,0,0,2.78.84c1.54,0,2.26-.76,2.26-1.72s-.6-1.56-2.16-2.14c-2.09-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.86,5.86,0,0,1,2.86.71L665.3,532a4.53,4.53,0,0,0-2.38-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.21,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M670.12,536.14a3.71,3.71,0,0,0,4,4,7.54,7.54,0,0,0,3.21-.6l.36,1.51a9.37,9.37,0,0,1-3.86.72c-3.57,0-5.71-2.35-5.71-5.85s2.06-6.25,5.44-6.25c3.79,0,4.8,3.33,4.8,5.46a8.79,8.79,0,0,1-.07,1Zm6.18-1.51c0-1.34-.55-3.42-2.92-3.42-2.14,0-3.07,2-3.24,3.42Z" fill="#bdccd4"/>
                    <path d="M680.66,539.4a5.54,5.54,0,0,0,2.78.84c1.54,0,2.26-.76,2.26-1.72s-.6-1.56-2.16-2.14c-2.09-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.86,5.86,0,0,1,2.86.71L686.8,532a4.49,4.49,0,0,0-2.37-.67c-1.25,0-1.94.72-1.94,1.58s.69,1.39,2.2,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.33,3.47a6.57,6.57,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                  </g>
                  <g id="assessments">
                    <path d="M169.45,536.48l-1.68,5.08h-2.16l5.49-16.16h2.52l5.51,16.16h-2.22l-1.73-5.08Zm5.3-1.63-1.58-4.65c-.36-1.06-.6-2-.84-3h-.05c-.24,1-.51,1.94-.82,2.92l-1.58,4.68Z" fill="#bdccd4"/>
                    <path d="M181,539.4a5.55,5.55,0,0,0,2.79.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.85,5.85,0,0,1,2.85.71l-.53,1.54a4.46,4.46,0,0,0-2.37-.67c-1.25,0-1.94.72-1.94,1.58s.69,1.39,2.2,2c2,.76,3.05,1.77,3.05,3.5,0,2-1.59,3.47-4.34,3.47a6.6,6.6,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M190.5,539.4a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.06-1.89-3.06-3.28,0-1.87,1.51-3.4,4-3.4a5.85,5.85,0,0,1,2.85.71l-.53,1.54a4.46,4.46,0,0,0-2.37-.67c-1.25,0-1.94.72-1.94,1.58s.69,1.39,2.2,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47A6.6,6.6,0,0,1,190,541Z" fill="#bdccd4"/>
                    <path d="M201.45,536.14a3.72,3.72,0,0,0,4,4,7.54,7.54,0,0,0,3.21-.6l.36,1.51a9.3,9.3,0,0,1-3.86.72c-3.57,0-5.7-2.35-5.7-5.85s2.06-6.25,5.44-6.25c3.79,0,4.79,3.33,4.79,5.46a6.87,6.87,0,0,1-.07,1Zm6.19-1.51c0-1.34-.56-3.42-2.93-3.42-2.13,0-3.07,2-3.23,3.42Z" fill="#bdccd4"/>
                    <path d="M212,539.4a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.15-2.14c-2.09-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.82,5.82,0,0,1,2.85.71l-.52,1.54a4.53,4.53,0,0,0-2.38-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.21,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                    <path d="M221.49,539.4a5.51,5.51,0,0,0,2.78.84c1.54,0,2.26-.76,2.26-1.72s-.6-1.56-2.16-2.14c-2.09-.74-3.07-1.89-3.07-3.28,0-1.87,1.51-3.4,4-3.4a5.86,5.86,0,0,1,2.86.71l-.53,1.54a4.53,4.53,0,0,0-2.38-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.21,2c2,.76,3,1.77,3,3.5,0,2-1.58,3.47-4.34,3.47A6.63,6.63,0,0,1,221,541Z" fill="#bdccd4"/>
                    <path d="M231.27,533.1c0-1.2,0-2.18-.09-3.14H233l.1,1.87h.07a4,4,0,0,1,3.64-2.13,3.43,3.43,0,0,1,3.29,2.32h0a4.62,4.62,0,0,1,1.29-1.51,3.92,3.92,0,0,1,2.56-.81c1.54,0,3.82,1,3.82,5v6.83h-2.07V535c0-2.23-.81-3.57-2.51-3.57a2.74,2.74,0,0,0-2.5,1.92,3.68,3.68,0,0,0-.16,1v7.17h-2.06v-6.95c0-1.85-.82-3.19-2.43-3.19a3.15,3.15,0,0,0-2.78,3.14v7h-2.06Z" fill="#bdccd4"/>
                    <path d="M252.44,536.14a3.72,3.72,0,0,0,4,4,7.54,7.54,0,0,0,3.21-.6l.36,1.51a9.3,9.3,0,0,1-3.86.72c-3.57,0-5.7-2.35-5.7-5.85s2.06-6.25,5.44-6.25c3.78,0,4.79,3.33,4.79,5.46a8.79,8.79,0,0,1-.07,1Zm6.18-1.51c0-1.34-.55-3.42-2.92-3.42-2.13,0-3.07,2-3.24,3.42Z" fill="#bdccd4"/>
                    <path d="M263.27,533.1c0-1.2,0-2.18-.09-3.14h1.87l.12,1.92h0a4.26,4.26,0,0,1,3.84-2.18c1.61,0,4.1.95,4.1,4.93v6.93H271v-6.69c0-1.87-.7-3.42-2.68-3.42a3.11,3.11,0,0,0-3,3.14v7h-2.11Z" fill="#bdccd4"/>
                    <path d="M279,526.63V530h3v1.61h-3v6.25c0,1.44.41,2.26,1.58,2.26a4.77,4.77,0,0,0,1.23-.15l.09,1.58a5.06,5.06,0,0,1-1.87.29,2.89,2.89,0,0,1-2.27-.89,4.28,4.28,0,0,1-.82-3v-6.32h-1.8V530H277v-2.78Z" fill="#bdccd4"/>
                    <path d="M284.13,539.4a5.47,5.47,0,0,0,2.78.84c1.53,0,2.25-.76,2.25-1.72s-.6-1.56-2.16-2.14c-2.08-.74-3.06-1.89-3.06-3.28,0-1.87,1.51-3.4,4-3.4a5.85,5.85,0,0,1,2.85.71l-.53,1.54a4.46,4.46,0,0,0-2.37-.67c-1.24,0-1.94.72-1.94,1.58s.7,1.39,2.2,2c2,.76,3.05,1.77,3.05,3.5,0,2-1.58,3.47-4.34,3.47a6.63,6.63,0,0,1-3.26-.79Z" fill="#bdccd4"/>
                  </g>
                  <g id="accountability">
                    <path d="M21.59,191.6l-2.09,6.88H14.93l7.78-24.27h5.65l7.88,24.27H31.49l-2.2-6.88Zm7-3.35-1.91-6c-.47-1.48-.86-3.13-1.22-4.54h-.08c-.35,1.41-.72,3.1-1.15,4.54l-1.87,6Z" fill="#fff"/>
                    <path d="M51.87,197.94a12.94,12.94,0,0,1-5,.89c-5.47,0-9-3.49-9-8.92,0-5.26,3.6-9.36,9.76-9.36a11,11,0,0,1,4.28.83l-.79,3.31a7.69,7.69,0,0,0-3.35-.69c-3.38,0-5.4,2.49-5.36,5.69,0,3.6,2.34,5.65,5.36,5.65a8.63,8.63,0,0,0,3.49-.68Z" fill="#fff"/>
                    <path d="M67.71,197.94a12.94,12.94,0,0,1-5,.89c-5.47,0-9-3.49-9-8.92,0-5.26,3.6-9.36,9.76-9.36a11,11,0,0,1,4.28.83L67,184.69A7.69,7.69,0,0,0,63.6,184c-3.38,0-5.4,2.49-5.36,5.69,0,3.6,2.34,5.65,5.36,5.65a8.63,8.63,0,0,0,3.49-.68Z" fill="#fff"/>
                    <path d="M87.33,189.55c0,6.44-4.54,9.32-9,9.32-5,0-8.79-3.42-8.79-9,0-5.76,3.78-9.29,9.07-9.29S87.33,184.22,87.33,189.55Zm-13.21.18c0,3.38,1.65,5.94,4.35,5.94,2.52,0,4.28-2.49,4.28-6,0-2.74-1.22-5.87-4.24-5.87S74.12,186.81,74.12,189.73Z" fill="#fff"/>
                    <path d="M106.66,193.22c0,2.09.07,3.82.14,5.26h-3.89l-.21-2.67h-.07A6.35,6.35,0,0,1,97,198.87c-3.2,0-6.12-1.91-6.12-7.63V181h4.43v9.53c0,2.92.93,4.79,3.27,4.79A3.74,3.74,0,0,0,102,192.9a4.18,4.18,0,0,0,.25-1.37V181h4.43Z" fill="#fff"/>
                    <path d="M111.45,186.16c0-2,0-3.7-.15-5.21h3.89l.22,2.62h.1a6.35,6.35,0,0,1,5.58-3c3.06,0,6.23,2,6.23,7.52v10.41h-4.43v-9.9c0-2.52-.93-4.43-3.34-4.43a3.72,3.72,0,0,0-3.46,2.59,4.66,4.66,0,0,0-.18,1.44v10.3h-4.46Z" fill="#fff"/>
                    <path d="M136.94,175.94v5h4.21v3.31h-4.21V192c0,2.12.57,3.24,2.26,3.24a6.45,6.45,0,0,0,1.73-.22l.07,3.38a9.53,9.53,0,0,1-3.2.43A5.17,5.17,0,0,1,134,197.4c-.93-1-1.36-2.6-1.36-4.9v-8.24H130.1V181h2.52v-4Z" fill="#fff"/>
                    <path d="M154.36,198.48l-.29-1.95H154a6.49,6.49,0,0,1-5.18,2.34,5.2,5.2,0,0,1-5.51-5.22c0-4.43,3.92-6.66,10.4-6.62v-.29c0-1.15-.46-3.06-3.56-3.06a9.2,9.2,0,0,0-4.71,1.3l-.87-2.88a12.65,12.65,0,0,1,6.34-1.55c5.61,0,7.23,3.56,7.23,7.38v6.33a28.21,28.21,0,0,0,.25,4.22Zm-.58-8.57c-3.13-.07-6.12.61-6.12,3.27a2.32,2.32,0,0,0,2.52,2.52,3.59,3.59,0,0,0,3.46-2.41,3.08,3.08,0,0,0,.14-1Z" fill="#fff"/>
                    <path d="M162.64,198.48c.07-1.19.14-3.14.14-4.94V172.92h4.43v10.44h.07a6.35,6.35,0,0,1,5.62-2.81c4.28,0,7.34,3.56,7.31,8.89,0,6.3-4,9.43-8,9.43a6,6,0,0,1-5.54-3h-.07l-.22,2.63Zm4.57-7.24a5,5,0,0,0,.11,1,4.08,4.08,0,0,0,3.92,3.1c2.77,0,4.47-2.23,4.47-5.76,0-3.1-1.48-5.62-4.43-5.62a4.16,4.16,0,0,0-3.93,3.24,5.5,5.5,0,0,0-.14,1.16Z" fill="#fff"/>
                    <path d="M188.56,176.05a2.49,2.49,0,1,1-2.49-2.41A2.35,2.35,0,0,1,188.56,176.05Zm-4.72,22.43V181h4.47v17.53Z" fill="#fff"/>
                    <path d="M193.06,172.92h4.46v25.56h-4.46Z" fill="#fff"/>
                    <path d="M207,176.05a2.36,2.36,0,0,1-2.52,2.38,2.33,2.33,0,0,1-2.45-2.38,2.49,2.49,0,0,1,5,0Zm-4.72,22.43V181h4.46v17.53Z" fill="#fff"/>
                    <path d="M216.6,175.94v5h4.21v3.31H216.6V192c0,2.12.58,3.24,2.27,3.24a6.45,6.45,0,0,0,1.73-.22l.07,3.38a9.53,9.53,0,0,1-3.2.43,5.17,5.17,0,0,1-3.82-1.43c-.94-1-1.37-2.6-1.37-4.9v-8.24h-2.52V181h2.52v-4Z" fill="#fff"/>
                    <path d="M227.19,181l3.16,9.39c.36,1.08.72,2.41,1,3.42h.11c.29-1,.61-2.3.94-3.46L235.1,181h4.75l-4.39,12c-2.41,6.55-4,9.46-6.12,11.26a8.82,8.82,0,0,1-4.64,2.27l-1-3.74a7.45,7.45,0,0,0,2.59-1.23,7.77,7.77,0,0,0,2.42-2.77,1.71,1.71,0,0,0,.28-.79,1.8,1.8,0,0,0-.25-.86L222.33,181Z" fill="#fff"/>
                  </g>
                  <g id="productivity">
                    <path d="M535.13,174.54a38.14,38.14,0,0,1,6.77-.51c3.24,0,5.61.69,7.16,2a6.87,6.87,0,0,1,2.38,5.37,7.32,7.32,0,0,1-2.09,5.5c-1.76,1.77-4.53,2.6-7.67,2.6a12.31,12.31,0,0,1-2.16-.15v9.15h-4.39Zm4.39,11.3a9.14,9.14,0,0,0,2.16.18c3.32,0,5.33-1.62,5.33-4.46s-1.87-4.14-4.93-4.14a13.06,13.06,0,0,0-2.56.21Z" fill="#fff"/>
                    <path d="M554.64,186.6c0-2.38,0-4.07-.14-5.65h3.85l.15,3.34h.14a5.23,5.23,0,0,1,4.79-3.74,4.74,4.74,0,0,1,1,.11v4.17a6.26,6.26,0,0,0-1.29-.14,3.9,3.9,0,0,0-4,3.35,6.81,6.81,0,0,0-.15,1.36v9.08h-4.43Z" fill="#fff"/>
                    <path d="M583.8,189.55c0,6.44-4.53,9.32-9,9.32-5,0-8.78-3.42-8.78-9,0-5.76,3.78-9.29,9.07-9.29S583.8,184.22,583.8,189.55Zm-13.21.18c0,3.38,1.66,5.94,4.36,5.94,2.52,0,4.28-2.49,4.28-6,0-2.74-1.22-5.87-4.25-5.87S570.59,186.81,570.59,189.73Z" fill="#fff"/>
                    <path d="M603.6,172.92v20.62c0,1.8.07,3.75.15,4.94h-4l-.18-2.78h-.08a6.29,6.29,0,0,1-5.72,3.17c-4.17,0-7.49-3.56-7.49-9,0-5.87,3.64-9.36,7.85-9.36a5.59,5.59,0,0,1,4.93,2.3h.07v-9.93Zm-4.43,15.33a6.38,6.38,0,0,0-.1-1.15,3.88,3.88,0,0,0-3.82-3.13c-2.84,0-4.43,2.52-4.43,5.79s1.59,5.55,4.39,5.55a3.91,3.91,0,0,0,3.82-3.13,4.89,4.89,0,0,0,.14-1.3Z" fill="#fff"/>
                    <path d="M624.05,193.22c0,2.09.07,3.82.14,5.26h-3.88l-.22-2.67H620a6.34,6.34,0,0,1-5.62,3.06c-3.2,0-6.12-1.91-6.12-7.63V181h4.43v9.53c0,2.92.94,4.79,3.28,4.79a3.74,3.74,0,0,0,3.38-2.37,4.18,4.18,0,0,0,.25-1.37V181h4.43Z" fill="#fff"/>
                    <path d="M641.73,197.94a12.94,12.94,0,0,1-5,.89c-5.47,0-9-3.49-9-8.92,0-5.26,3.6-9.36,9.76-9.36a11,11,0,0,1,4.28.83l-.79,3.31a7.69,7.69,0,0,0-3.35-.69c-3.38,0-5.4,2.49-5.36,5.69,0,3.6,2.34,5.65,5.36,5.65a8.63,8.63,0,0,0,3.49-.68Z" fill="#fff"/>
                    <path d="M650.62,175.94v5h4.21v3.31h-4.21V192c0,2.12.57,3.24,2.27,3.24a6.36,6.36,0,0,0,1.72-.22l.08,3.38a9.55,9.55,0,0,1-3.21.43,5.13,5.13,0,0,1-3.81-1.43c-.94-1-1.37-2.6-1.37-4.9v-8.24h-2.52V181h2.52v-4Z" fill="#fff"/>
                    <path d="M662.89,176.05a2.48,2.48,0,1,1-2.48-2.41A2.35,2.35,0,0,1,662.89,176.05Zm-4.71,22.43V181h4.46v17.53Z" fill="#fff"/>
                    <path d="M670.2,181l2.85,8.89c.5,1.54.86,2.95,1.18,4.39h.11c.33-1.44.72-2.81,1.19-4.39L678.3,181H683l-6.69,17.53h-4.36L665.41,181Z" fill="#fff"/>
                    <path d="M690.4,176.05a2.49,2.49,0,1,1-2.48-2.41A2.35,2.35,0,0,1,690.4,176.05Zm-4.72,22.43V181h4.47v17.53Z" fill="#fff"/>
                    <path d="M700,175.94v5h4.21v3.31H700V192c0,2.12.58,3.24,2.27,3.24A6.45,6.45,0,0,0,704,195l.07,3.38a9.53,9.53,0,0,1-3.2.43A5.17,5.17,0,0,1,697,197.4c-.93-1-1.37-2.6-1.37-4.9v-8.24h-2.52V181h2.52v-4Z" fill="#fff"/>
                    <path d="M710.56,181l3.17,9.39c.35,1.08.72,2.41,1,3.42h.1c.29-1,.62-2.3.94-3.46l2.74-9.35h4.75l-4.39,12c-2.42,6.55-4,9.46-6.12,11.26a8.87,8.87,0,0,1-4.65,2.27l-1-3.74a8,8,0,0,0,5-4,1.75,1.75,0,0,0,.29-.79,1.93,1.93,0,0,0-.25-.86L705.7,181Z" fill="#fff"/>
                  </g>
                  <g id="compliance">
                    <path d="M545.89,623.92a15.38,15.38,0,0,1-6.27,1.08c-7.7,0-12.38-4.83-12.38-12.21,0-8,5.55-12.81,13-12.81a13.87,13.87,0,0,1,5.9,1.08l-1,3.53a11.65,11.65,0,0,0-4.75-.94c-4.93,0-8.49,3.1-8.49,8.93,0,5.33,3.13,8.75,8.45,8.75a12.42,12.42,0,0,0,4.83-.9Z" fill="#fff"/>
                    <path d="M565.58,615.71c0,6.44-4.54,9.32-9,9.32-5,0-8.78-3.42-8.78-9s3.78-9.29,9.07-9.29S565.58,610.38,565.58,615.71Zm-13.21.18c0,3.38,1.66,5.94,4.36,5.94,2.52,0,4.28-2.48,4.28-6,0-2.74-1.22-5.87-4.25-5.87S552.37,613,552.37,615.89Z" fill="#fff"/>
                    <path d="M569.22,612.33c0-2,0-3.71-.15-5.22h3.82l.18,2.59h.11a6,6,0,0,1,5.4-3,5.07,5.07,0,0,1,4.86,3.24h.07a6.69,6.69,0,0,1,2.16-2.2,5.88,5.88,0,0,1,3.53-1c2.91,0,5.86,2,5.86,7.6v10.33h-4.32V615c0-2.91-1-4.64-3.13-4.64a3.36,3.36,0,0,0-3.09,2.34,6.21,6.21,0,0,0-.22,1.48v10.51H580V614.49c0-2.45-1-4.18-3-4.18a3.46,3.46,0,0,0-3.17,2.52,3.8,3.8,0,0,0-.25,1.44v10.37h-4.32Z" fill="#fff"/>
                    <path d="M599.75,612.94c0-2.31-.08-4.18-.15-5.83h3.89l.22,2.7h.07a6.81,6.81,0,0,1,6-3.1c4,0,7.38,3.46,7.38,8.93,0,6.33-4,9.39-8,9.39a5.73,5.73,0,0,1-4.93-2.34h-.08v9.07h-4.42Zm4.42,4.35a4.51,4.51,0,0,0,.15,1.23,4,4,0,0,0,3.89,3.09c2.8,0,4.46-2.34,4.46-5.79,0-3.1-1.51-5.62-4.36-5.62a4.14,4.14,0,0,0-4,3.28,4.29,4.29,0,0,0-.18,1.11Z" fill="#fff"/>
                    <path d="M620.81,599.08h4.46v25.56h-4.46Z" fill="#fff"/>
                    <path d="M634.77,602.21a2.49,2.49,0,1,1-2.48-2.41A2.35,2.35,0,0,1,634.77,602.21Zm-4.71,22.43V607.11h4.46v17.53Z" fill="#fff"/>
                    <path d="M649.14,624.64l-.29-1.95h-.11a6.51,6.51,0,0,1-5.18,2.34,5.2,5.2,0,0,1-5.51-5.22c0-4.42,3.92-6.66,10.4-6.62v-.29c0-1.15-.47-3.06-3.56-3.06a9.26,9.26,0,0,0-4.72,1.3l-.86-2.88a12.6,12.6,0,0,1,6.33-1.55c5.62,0,7.24,3.56,7.24,7.38v6.34a28.15,28.15,0,0,0,.25,4.21Zm-.58-8.57c-3.13-.07-6.12.61-6.12,3.28a2.33,2.33,0,0,0,2.52,2.52,3.61,3.61,0,0,0,3.46-2.42,3.4,3.4,0,0,0,.14-1Z" fill="#fff"/>
                    <path d="M657.56,612.33c0-2,0-3.71-.14-5.22h3.88l.22,2.62h.11a6.32,6.32,0,0,1,5.58-3c3.06,0,6.22,2,6.22,7.52v10.41H669v-9.9c0-2.52-.94-4.43-3.35-4.43a3.73,3.73,0,0,0-3.46,2.59,4.66,4.66,0,0,0-.18,1.44v10.3h-4.46Z" fill="#fff"/>
                    <path d="M691,624.1a12.87,12.87,0,0,1-5,.9c-5.47,0-9-3.5-9-8.93,0-5.26,3.6-9.36,9.75-9.36a11,11,0,0,1,4.28.83l-.79,3.31a7.86,7.86,0,0,0-3.35-.68c-3.38,0-5.39,2.48-5.36,5.68,0,3.6,2.34,5.65,5.36,5.65a8.73,8.73,0,0,0,3.5-.68Z" fill="#fff"/>
                    <path d="M697.12,617.11c.11,3.17,2.6,4.54,5.4,4.54a13.22,13.22,0,0,0,4.86-.79l.65,3.06a16.54,16.54,0,0,1-6.12,1.08c-5.69,0-9-3.5-9-8.86,0-4.86,3-9.43,8.56-9.43s7.56,4.68,7.56,8.53a11.1,11.1,0,0,1-.14,1.87Zm7.71-3.09c0-1.62-.69-4.29-3.64-4.29-2.73,0-3.89,2.49-4.07,4.29Z" fill="#fff"/>
                  </g>
                  <g id="quality">
                    <path d="M133,628.52a95.32,95.32,0,0,1-9.46-3.13,6.51,6.51,0,0,0-1.59-.36c-5.61-.28-10.62-4.42-10.62-12.31,0-7.48,4.65-12.74,11.59-12.74s11.2,5.4,11.2,12.24c0,5.87-2.7,9.79-6.26,11.19v.15c2.08.57,4.42,1.08,6.4,1.47Zm-3.56-16.09c0-4.64-2.27-8.89-6.66-8.89s-6.8,4.21-6.77,9.07,2.45,8.86,6.7,8.86S129.4,617.51,129.4,612.43Z" fill="#fff"/>
                    <path d="M153.56,619.38c0,2.09.07,3.82.14,5.26h-3.89L149.6,622h-.08a6.32,6.32,0,0,1-5.61,3.06c-3.21,0-6.12-1.91-6.12-7.63V607.11h4.43v9.54c0,2.91.93,4.78,3.27,4.78a3.74,3.74,0,0,0,3.39-2.37,4.18,4.18,0,0,0,.25-1.37V607.11h4.43Z" fill="#fff"/>
                    <path d="M168.21,624.64l-.29-1.95h-.11a6.49,6.49,0,0,1-5.18,2.34,5.2,5.2,0,0,1-5.51-5.22c0-4.42,3.92-6.66,10.4-6.62v-.29c0-1.15-.46-3.06-3.56-3.06a9.26,9.26,0,0,0-4.72,1.3l-.86-2.88a12.65,12.65,0,0,1,6.34-1.55c5.61,0,7.23,3.56,7.23,7.38v6.34a28.15,28.15,0,0,0,.25,4.21Zm-.58-8.57c-3.13-.07-6.12.61-6.12,3.28a2.33,2.33,0,0,0,2.52,2.52,3.6,3.6,0,0,0,3.46-2.42,3.08,3.08,0,0,0,.14-1Z" fill="#fff"/>
                    <path d="M176.63,599.08h4.47v25.56h-4.47Z" fill="#fff"/>
                    <path d="M190.6,602.21a2.49,2.49,0,1,1-2.48-2.41A2.35,2.35,0,0,1,190.6,602.21Zm-4.72,22.43V607.11h4.47v17.53Z" fill="#fff"/>
                    <path d="M200.18,602.1v5h4.21v3.31h-4.21v7.74c0,2.12.57,3.24,2.26,3.24a6.45,6.45,0,0,0,1.73-.22l.07,3.39a9.75,9.75,0,0,1-3.2.43,5.14,5.14,0,0,1-3.82-1.44c-.93-1-1.36-2.59-1.36-4.9v-8.24h-2.52v-3.31h2.52v-4Z" fill="#fff"/>
                    <path d="M210.76,607.11l3.17,9.39c.36,1.08.72,2.41,1,3.42H215c.28-1,.61-2.3.93-3.45l2.74-9.36h4.75L219,619.06c-2.41,6.55-4,9.46-6.12,11.26a8.88,8.88,0,0,1-4.64,2.27l-1-3.74a8,8,0,0,0,5-4,1.75,1.75,0,0,0,.29-.79,1.93,1.93,0,0,0-.25-.86l-6.41-16.09Z" fill="#fff"/>
                  </g>
                  <g id="arrows">
                    <polygon points="417.11 488.15 381.32 487.71 380.77 532.07 370.09 531.94 398.38 563.82 427.95 532.65 417.39 532.52 417.11 488.15" fill="#bdccd4"/>
                    <polygon points="311.56 415.5 311.99 379.71 267.63 379.17 267.76 368.49 235.89 396.77 267.05 426.34 267.18 415.79 311.56 415.5" fill="#bdccd4"/>
                    <polygon points="384.2 309.95 419.99 310.39 420.54 266.02 431.22 266.15 402.93 234.28 373.36 265.44 383.92 265.57 384.2 309.95" fill="#bdccd4"/>
                    <polygon points="489.75 382.59 489.31 418.39 533.68 418.93 533.55 429.61 565.42 401.33 534.26 371.76 534.13 382.31 489.75 382.59" fill="#bdccd4"/>
                  </g>
                  <polygon id="logo_W" data-name="logo W" points="343.96 380.03 414.67 380.03 393.91 420.24 384.31 420.24 378.99 410.77 374.58 419.85 366.15 419.85 343.96 380.03" fill="#fff"/>
                  <polygon id="logo_A" data-name="logo A" points="428.55 380.03 405.19 419.98 451.64 419.98 428.55 380.03" fill="#fff"/>
                  <circle id="logo_circle" data-name="logo circle" cx="398.65" cy="399.1" r="69.28" fill="none" stroke="#bdccd4" stroke-miterlimit="10" stroke-width="2"/>
                </svg>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Development section -->
        <div class="relative bg-gray-50 py-16 sm:py-24 lg:py-32">

            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase">walkabout</h2>
              <p class="mt-2 pb-5 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                VALUE PROPOSITION
              </p>

            <div class="grid grid-cols-1 gap-4 sm:grid-cols-4">
              <div v-for="value in Values" :key="value.description" class="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <div class="flex-1 min-w-0">

                    <span class="absolute inset-0" aria-hidden="true" />
                    <p class="text-sm font-medium text-gray-700">
                      {{ value.description }}
                    </p>
                </div>
              </div>
            </div>

          </div>

        </div>

    </main>

      <!-- FOOTER -->

      <footer class="bg-gray-200" aria-labelledby="footer-heading" id="products">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center zIndex:1">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-100 rounded-lg p-6" id="gsapFooter">
                    <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img  src="..\assets\logos\DevInspire_Logo_dark.png" alt="Dev Inspire" />
                      </div>
                    </router-link>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-700 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-700 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-500 font-medium mt-5 text-left">
                        &copy; 2021 Dev Inspire. All rights reserved.
                      </p>
                     </div>
                  </div>
              </div>

        </div>
      </footer>
    </div>
  </div>
</template>

<script src=".\walkabout.js"></script>
