<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="md:flex items-center flex-1">
              <div class="flex items-center w-full">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>

              <div class="sm:space-x-3 md:space-x-5 md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-bold hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;
                <span class="text-base font-medium text-yellow-500">></span>&nbsp;
                <span class="text-base font-bold text-yellow-500">Incenti5</span>&nbsp;
                <router-link to="/Walkabout" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Walkabout</router-link>&nbsp;
                <router-link to="/Bika" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Bika</router-link>&nbsp;
                <router-link to="/Tokaly" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Tokaly</router-link>&nbsp;
                <router-link to="/VExpoLab" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">VExpoLab</router-link>
              </div>

            </div>
          </nav>
        </div>

      <main>
        <div class="pt-10 bg-gray-900 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <!--<a href="#" class="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                    <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-full">We're hiring</span>
                    <span class="ml-4 text-sm">Visit our careers page</span>
                    <ChevronRightIcon class="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a>-->

                  <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span class="block" id="gsapHeader">Incenti-5</span>
                    <span class="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">Promotional System</span>
                  </h1>
                  <!--<p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                    We create solutions for getting competitive
                  </p>-->
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                   Incenti-5 is a complete promotional system designed to Increase Loyalty through rewards and incentives.  Increased turnover is driven through managed promotions and customer incentives. </div>
                  <!--<div class="mt-10 sm:mt-12">
                    <form action="#" class="sm:max-w-xl sm:mx-auto lg:mx-0">
                      <div class="sm:flex">
                        <div class="min-w-0 flex-1">
                          <label for="email" class="sr-only">Email address</label>
                          <input id="email" type="email" placeholder="Enter your email" class="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900" />
                        </div>
                        <div class="mt-3 sm:mt-0 sm:ml-3">
                          <button type="submit" class="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-yellow-500 to-yellow-700 text-white font-medium hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900">Subscribe today</button>
                        </div>
                      </div>
                      <p class="mt-3 text-sm text-gray-300 sm:mt-4"> Subscribe to <a href="#" class="font-medium text-white">our e-mailer</a>.</p>
                    </form>
                  </div>-->
                </div>
              </div>
              <div class="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">

                  <!--<img id="gsapHeaderImage" class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/logos/incenti5.svg" alt="" />-->
                  <!-- VExpoLab_pnp_dairy_portrait.png -->
                  <!--Illustration.svg-->
                  <div class="container" id="gsapHeaderImage">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                      <g id="cog">
                        <g id="back_cog" data-name="back cog">
                          <path d="M94.31,183.57c-16,2.34-21.77,11.06-27,23.43-2.82,6.69-17.24,16.08-23.31,15.68a53,53,0,0,0-14,1.5c1.51-.22,4.73-.54,6.53-.63,9.12-.47,22.11,6.12,28.27,23.51a11.4,11.4,0,0,0,2.29,3.51c2.18-2.84,4.24-4,7-5.95,4.35-3.05,6.43-5.83,21.55-6.25,13.76-.38,24.81-9,27.75-23.79S111.78,181,94.31,183.57Zm11.82,8.83a18.17,18.17,0,1,1-23.88,9.49A18.15,18.15,0,0,1,106.13,192.4Z" fill="#74d14c"/>
                          <circle cx="98.64" cy="208.18" r="10.14" fill="#f2af58"/>
                          <circle cx="98.05" cy="205.3" r="10.14" fill="#9a61a8" opacity="0.81"/>
                          <rect x="-55.88" y="161.38" width="179.59" height="179.59" fill="none"/>
                        </g>
                        <g id="front_cog" data-name="front cog">
                          <path d="M12.68,236.39c5-6.73,8.88-12.17,23.94-12.95,9.13-.47,22.3,6.55,28.46,23.94,2,5.73,16.37,15.26,23.63,15.21,13.43-.1,23.71,1.71,32.2,15.52,9.25,15-2.22,35.74-17,38.91s-27-3.58-32.83-16.07c-6.37-13.72-9.75-14.52-14.27-17.31-5-3.06-7.1-5.14-19.73-5C25.62,278.79,17,271.26,13,265.4S5.59,246,12.68,236.39Zm20.23-3.88a18,18,0,1,0,18,18A18,18,0,0,0,32.91,232.51Zm65.51,42.68a18.17,18.17,0,1,0,18.16,18.17A18.16,18.16,0,0,0,98.42,275.19Z" fill="#3a80af"/>
                          <circle cx="33.4" cy="251.39" r="10.14" fill="#f2af58"/>
                          <circle cx="32.91" cy="247.84" r="10.14" fill="#9a61a8" opacity="0.81"/>
                          <circle cx="99.95" cy="293.74" r="10.14" fill="#f2af58"/>
                          <circle cx="99.02" cy="290.95" r="10.14" fill="#9a61a8" opacity="0.8"/>
                          <rect x="-58.34" y="156.59" width="182.5" height="182.5" fill="none"/>
                        </g>
                      </g>
                      <g id="text">
                        <text id="text5" transform="translate(438.85 284.8)" font-size="92.2" fill="#f2af58" font-family="AllertaStencil-Regular, Allerta Stencil">5</text>
                        <text id="text5" transform="translate(437.67 281.92)" opacity="0.81" font-size="92.2" fill="#9a61a8" font-family="AllertaStencil-Regular, Allerta Stencil">5</text>
                        <text id="textIncenti" transform="translate(136.58 282.22)" font-size="92.2" fill="#3a80af" font-family="AllertaStencil-Regular, Allerta Stencil"><tspan letter-spacing="-0.02em">I</tspan><tspan x="26.11" y="0" letter-spacing="-0.04em">n</tspan><tspan x="79.38" y="0" letter-spacing="-0.05em">c</tspan><tspan x="127.24" y="0" letter-spacing="-0.05em">e</tspan><tspan x="182.48" y="0" letter-spacing="-0.03em">n</tspan><tspan x="237.09" y="0" letter-spacing="0em">t</tspan><tspan x="274.88" y="0">i</tspan></text>
                    </g>
                  </svg>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Development section -->
        <div class="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase">APPLICATION DEVELOPMENT</h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                About Incenti-5
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">

              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              <div v-for="item in Components" :key="item.id" class="flex flex-col rounded-lg shadow-lg overflow-hidden" id="gsapDev">
                <div class="flex-shrink-0">
                  <img class="h-48 w-full object-cover" :src="item.imageUrl" alt="" :id="item.tweenId" />
                </div>
                <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                  <div class="flex-1">
                    <p class="text-sm font-medium text-yellow-600">
                      <a :href="item.category.href" class="hover:underline">
                        {{ item.category.name }}
                      </a>
                    </p>
                    <a :href="item.href" class="block mt-2">
                      <p class="text-xl font-semibold text-gray-900">
                        {{ item.title }}
                      </p>
                      <p class="mt-3 text-base text-gray-500">
                        {{ item.preview }}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Widgets section -->
        <div class="relative bg-gray-200 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase"></h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Incenti-5 Draws and Widgets
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">

              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              <div v-for="product in products" :key="product.name" class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-gray-50" id="gsapDev">
                <a :id="product.hrefId" :href="product.href" >
                  <div class="flex-shrink-0 bg-white">
                    <img class="h-48 w-full object-cover" :src="product.imageUrl" alt="" />
                  </div>
                  <div class="flex-1 p-6 flex flex-col justify-between border-t-2 border-gray-200">
                    <div class="flex-1">
                        <p class="text-xl font-semibold text-gray-900">
                          {{ product.name }}
                        </p>
                        <p class="mt-3 text-base text-gray-500">
                          {{ product.description }}
                        </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Games section -->
        <div class="relative bg-gray-900 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase">fun stuff</h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-100 tracking-tight sm:text-4xl">
                Incenti-5 Games
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">

              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              <div v-for="product in games" :key="product.name" class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-gray-100 border-2 border-gray-600" id="gsapDev">
                <a :id="product.hrefId" :href="product.href" >
                  <div class="flex-shrink-0 bg-white">
                    <img class="h-48 w-full object-cover" :src="product.imageUrl" alt="" />
                  </div>
                  <div class="flex-1 p-6 flex flex-col justify-between border-t-2 border-gray-200">
                    <div class="flex-1">
                        <p class="text-xl font-semibold text-gray-900">
                          {{ product.name }}
                        </p>
                        <p class="mt-3 text-base text-gray-500">
                          {{ product.description }}
                        </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

    </main>

      <!-- FOOTER -->

      <footer class="bg-gray-200" aria-labelledby="footer-heading" id="products">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center zIndex:1">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-100 rounded-lg p-6" id="gsapFooter">
                    <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img  src="..\assets\logos\DevInspire_Logo_dark.png" alt="Dev Inspire" />
                      </div>
                    </router-link>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-700 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-700 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-500 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                     </div>
                  </div>
              </div>

        </div>
      </footer>
    </div>
  </div>
</template>

<script src=".\incenti5.js"></script>
