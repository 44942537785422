<template>
  <div class="bg-white">
    <div class="relative overflow-hidden">

        <div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="md:flex items-center flex-1">
              <div class="flex items-center w-full">
                <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>

              <div class="sm:space-x-3 md:space-x-5 md:flex md:ml-10 text-gray-300" >
                <router-link :to="'/#productsBlock'" class="text-base font-bold hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Products</router-link>&nbsp;
                <span class="text-base font-medium text-yellow-500">></span>&nbsp;
                <router-link to="/incenti5" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;" >Incenti5</router-link>&nbsp;
                <router-link to="/Walkabout" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Walkabout</router-link>&nbsp;
                <router-link to="/Bika" class="text-base font-bold text-yellow-500 hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Bika</router-link>&nbsp;
                <router-link to="/Tokaly" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Tokaly</router-link>&nbsp;
                <router-link to="/VExpoLab" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">VExpoLab</router-link>
              </div>
            </div>

          </nav>
        </div>

      <main>
        <div class="pt-10 bg-gray-800 sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div class="mx-auto max-w-7xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div class="lg:py-24">
                  <!--<a href="#" class="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200">
                    <span class="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-yellow-500 to-yellow-700 rounded-full">We're hiring</span>
                    <span class="ml-4 text-sm">Visit our careers page</span>
                    <ChevronRightIcon class="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a>-->
                  <h1 class="mt-3 text-3xl tracking-tight font-extrabold sm:mt-4 sm:text-5xl lg:mt-5 xl:text-5xl bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-700 sm:pb-5" id="gsapHeader">
                    <span class="text-white"></span>BIKA is a corporate communications platform and feedback tool.<!--is the Zulu word for “Report”.-->
                  </h1>
                  <div class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl" id="gsapHeader">
                   Frequently asked questions page.
                   </div>

                </div>
              </div>
              <div class="mt-12 -mb-0 sm:-mb-10 lg:m-0 lg:relative">
                <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">

                  <!--<img id="gsapHeaderImage" class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/logos/bika.svg" alt="" />-->
                  <!-- VExpoLab_pnp_dairy_portrait.png -->
                  <!--Illustration.svg-->
                  <div class="container" id="gsapHeaderImage">

                    <svg id="logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 500 500">
                    <defs>
                      <linearGradient id="linear-gradient" x1="129.68" y1="423.33" x2="338.75" y2="61.22" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#4da659"/>
                        <stop offset="0.24" stop-color="#4f8aef"/>
                        <stop offset="0.65" stop-color="#dd433c"/>
                        <stop offset="1" stop-color="#f4b934"/>
                      </linearGradient>
                    </defs>
                    <g id="chatBubble_group">
                      <g id="chatBubble">
                        <path d="M118.55,416.9s12.61-28.59,23.14-50.08c6.17-12.61,7.22-21,7.22-21-2.92-.5-96.85-4.23-95.68-134.43C54,122.66,77.66,76.32,254.13,75c153.73-1.18,195.48,29.48,196.25,135.75.74,100.84-48.28,125.37-104.52,132.35-81.13,10.07-143.41,8.15-143.41,8.15s-11.1,18-34.76,37.41C153.56,400.22,118.55,416.9,118.55,416.9Z" fill="#fff"/>
                      </g>
                      <path id="chatBubble_outline" d="M262.48,74.93c146.91,0,187.14,31.45,187.9,135.78.74,100.84-48.28,125.37-104.52,132.35a1088.11,1088.11,0,0,1-130,8.29c-8.61,0-13.38-.14-13.38-.14s-11.1,18-34.76,37.41c-14.13,11.6-49.14,28.28-49.14,28.28s12.61-28.59,23.14-50.08c6.17-12.61,7.22-21,7.22-21-2.92-.5-96.85-4.23-95.68-134.43C54,122.66,77.66,76.32,254.13,75l8.35,0m0-14.23-8.46,0c-89.18.69-143.21,12.61-175.2,38.65C63.29,112,52.73,128.16,46.52,148.73c-5,16.42-7.28,35.72-7.52,62.55-.27,30.7,4.52,57.45,14.24,79.5,8.19,18.56,19.87,33.89,34.7,45.55a114.32,114.32,0,0,0,42.57,20.77c-.48,1.1-1,2.26-1.6,3.47-10.53,21.49-22.86,49.41-23.38,50.59L90.05,446.25l34.62-16.5c3.75-1.79,37-17.79,52.05-30.13a202.33,202.33,0,0,0,33-34.06l6.08,0a1100.69,1100.69,0,0,0,131.78-8.4c23.28-2.89,57.12-9.67,82.05-35.15,23.89-24.41,35.32-60.86,34.95-111.43-.21-29.12-3.33-51.31-9.82-69.83-7.23-20.64-18.73-36.52-35.14-48.55C389.51,70.13,342.5,60.7,262.48,60.7Z" fill="url(#linear-gradient)"/>
                    </g>
                    <g id="b">
                      <path d="M84.22,140H111.7v40.28c7.34-4.14,12.43-5.65,20.33-5.65,25.79,0,36.52,13,36.52,43.86v14.12c0,31.05-10.73,43.85-36.71,43.85-11.86,0-17.31-1.69-24.84-8.09l-1.7,6H84.22Zm57,80.56c0-16.75-4.51-24.09-14.68-24.09s-14.87,5.83-14.87,18.63V236c0,12.8,4.52,18.64,14.87,18.64,10.17,0,14.68-7.15,14.68-24.09Z" fill="#dd433c"/>
                    </g>
                    <g id="i">
                      <path d="M191.13,138.52h27.48v25.79H191.13Zm0,38.4h27.48v97.5H191.13Z" fill="#f4b934"/>
                    </g>
                    <g id="k">
                      <path d="M244.4,140h27.48V218l30.87-41H333.8l-33.69,41.41,34.82,56.09H305l-21.27-36.33L271.88,253v21.46H244.4Z" fill="#4da659"/>
                    </g>
                    <g id="a">
                      <path d="M397.05,211.55c0-10.35-4.52-15.05-14.31-15.05-7.34,0-13.17,3-18.26,9.41l-18.82-13.18c8.47-12,22-18.07,39.91-18.07,18.07,0,30.49,6.21,35.57,17.51,2.45,5.46,3.39,12.23,3.39,24.47v57.78H403.07l-1.69-5.08c-6,5.08-13.18,7.15-25.79,7.15-23.9,0-32.94-8.47-32.94-30.87,0-23.52,8.85-30.87,36.52-30.87h17.88Zm-14.5,21.27c-10.16,0-14.11,3.39-14.11,12.05,0,8.28,3.76,11.11,14.3,11.11,11.11,0,14.31-3.39,14.31-15.25v-7.91Z" fill="#4f8aef"/>
                    </g>
                  </svg>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Bika Icons -->
        <div class="relative bg-white py-16 sm:py-24 lg:py-32"  >
          <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase">bika</h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Tools
            </p>
            <!--<p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
              We’re proud to be your partner – Welcome to the DevInspire family.
            </p>-->
            <div class="mt-12" >
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div v-for="item in icons" :key="item.name" class="pt-6  bg-gradient-to-t from-gray-100 to-white" id="gsapModules"> <!--:id="item.id"-->
                  <div class="flow-root bg-gray-100 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center">
                          <img class="h-20 w-20" :src="item.imageUrl" alt="" />
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">{{ item.name }}</h3>
                      <p class="mt-5 text-base text-gray-500">
                        {{ item.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Case Study section -->
        <div class="relative bg-gray-200 py-16 sm:py-24 lg:py-32" id="products">
          <div class="relative">
            <div class="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 class="text-base font-semibold tracking-wider text-yellow-600 uppercase">bika</h2>
              <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Case Study
              </p>
              <p class="mt-5 mx-auto max-w-prose text-xl text-gray-500">
                Clients in a variety of industries who have customized the BIKA to suit their individual requirements.
              </p>
            </div>
            <div class="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
              <div v-for="product in games" :key="product.name" class="flex flex-col rounded-lg shadow-lg overflow-hidden bg-gray-50" id="gsapDev">
                <a :id="product.hrefId" :href="product.href" >
                  <div class="flex-shrink-0 bg-white">
                    <img class="h-48 w-full object-cover" :src="product.imageUrl" alt="" />
                  </div>
                  <div class="flex-1 p-6 flex flex-col justify-between border-t-2 border-gray-200">
                    <div class="flex-1">
                        <p class="text-xl font-semibold text-gray-900">
                          {{ product.name }}
                        </p>
                        <p class="mt-3 text-base text-gray-500">
                          {{ product.description }}
                        </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- FAQ section -->
    <div class="bg-gray-900">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="lg:max-w-2xl lg:mx-auto lg:text-center">
        <h2 class="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          Frequently asked questions (FAQ)
        </h2>
        <!--<p class="mt-4 text-gray-400">Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec. Urna, sed a lectus elementum blandit et.</p>-->
      </div>
      <div class="mt-20">
        <dl class="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
          <div v-for="faq in faqs" :key="faq.id">
            <dt class="font-semibold text-white">
              {{ faq.question }}
            </dt>
            <dd class="mt-3 text-gray-400">
              {{ faq.answer }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>

    </main>

      <!-- FOOTER -->

      <footer class="bg-gray-200" aria-labelledby="footer-heading" id="products">
        <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center zIndex:1">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-100 rounded-lg p-6" id="gsapFooter">
                    <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img  src="..\assets\logos\DevInspire_Logo_dark.png" alt="Dev Inspire" />
                      </div>
                    </router-link>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-700 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.name" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-700 font-medium mt-2 m-auto" style="width:280px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
                          <span class="sr-only">{{ item.name }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-500 font-medium mt-5 text-left">
                        &copy; 2021 Dev Inspire. All rights reserved.
                      </p>
                     </div>
                  </div>
              </div>

        </div>
      </footer>
    </div>
  </div>
</template>

<script src=".\faq.js"></script>
