
<template>
  <div class="bg-gray-900 h-full" ref="top">
    <div class="relative overflow-hidden h-full">
        <!--<div class="bg-black pt-6">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between w-full ">
                  <span class="sr-only">Tokaly</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
              </div>
              <div class="  w-52 text-gray-300">-->
                <!--<a class="text-base font-medium hover:text-white" @click="scrollToAnchorPoint('productsBlock')" style="cursor: pointer; text-decoration-line: underline;text-decoration-color: darkslategrey;" >Products</a>-->
                <!--
                <router-link to="/contact-us" class="text-base font-medium hover:text-white" style="text-decoration-line: underline;text-decoration-color: darkslategrey;">Contact Us</router-link>
              </div>
            </div>
          </nav>
        </div>-->

        <div class=" bg-background-image bg-repeat-x sm:pt-16 lg:pt-8  mx-auto max-w-7xl ">
          <nav class="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">
            <div class="flex items-center flex-1">
              <div class="flex items-center justify-between w-full ">
                  <router-link :to="'/'">
                  <span class="sr-only">Workflow</span>
                  <img class="h-10 w-auto sm:h-16" src="..\assets\logos\DevInspire_Logo.png" alt="" />
                </router-link>
              </div>
              <div class=" w-52 text-white font-bold">
                Contact Us
              </div>
            </div>
          </nav>
          <div class="mx-auto max-w-4xl  mb-0 ">
                  <div class="container mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6  " id="gsapHeaderImage">
                    <svg version="1.1" id="Tokaly" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 850 350" style="enable-background:new 0 0 850 350;" xml:space="preserve">
<image id="PolygonPink" x="0" y="0" width="850" height="350" xlink:href="logo/tokaly_magenta.png" />
<image id="PolygonBlue" x="0" y="0" width="850" height="350" xlink:href="logo/tokaly_teal.png" />
<image id="PolygonYellow" x="0" y="0" width="850" height="350" xlink:href="logo/tokaly_yellow.png" />
<image id="TokalyText" x="0" y="0" width="850" height="350" xlink:href="logo/tokaly_text.png" />

<g id="Marketing" opacity="0.7">
	<path fill="#F4F4F4" d="M102.9,306.6c-0.3-5-0.6-11-0.5-15.4h-0.2c-1.3,4.2-2.8,8.6-4.5,13.6l-6.3,17.3h-3.6L82.2,305
		c-1.6-5-3.1-9.7-4.2-13.9h-0.2c-0.2,4.5-0.3,10.5-0.6,15.9l-1,15.4h-4.4l2.4-35.8H80l6.2,17.3c1.5,4.4,2.8,8.3,3.6,12H90
		c1-3.6,2.1-7.6,3.7-12l6.3-17.3h5.8l2.3,35.8h-4.5L102.9,306.6z"/>
	<path fill="#F4F4F4" d="M129.9,322.3l-0.3-3.2h-0.2c-1.5,1.9-4.2,3.9-7.9,3.9c-5.2,0-7.9-3.7-7.9-7.4c0-6.2,5.5-9.5,15.4-9.5v-0.5
		c0-2.1-0.6-6-5.8-6c-2.4,0-4.9,0.8-6.6,1.9l-1.1-3.1c2.1-1.5,5.2-2.3,8.4-2.3c7.9,0,9.7,5.3,9.7,10.5v9.5c0,2.3,0.2,4.4,0.5,6.2
		H129.9z M129.1,309.2c-5-0.2-10.8,0.8-10.8,5.8c0,3.1,1.9,4.5,4.4,4.5c3.4,0,5.5-2.1,6.2-4.4c0.2-0.5,0.3-1,0.3-1.5v-4.4H129.1z"/>
	<path fill="#F4F4F4" d="M141.2,304.7c0-3.1,0-5.7-0.2-8.1h4l0.2,5h0.2c1.1-3.4,4-5.7,7.1-5.7c0.5,0,1,0,1.3,0.2v4.4
		c-0.5-0.2-1-0.2-1.6-0.2c-3.2,0-5.7,2.4-6.3,6c-0.2,0.6-0.2,1.5-0.2,2.1v13.8H141v-17.5L141.2,304.7L141.2,304.7z"/>
	<path fill="#F4F4F4" d="M163.4,308.4L163.4,308.4c0.8-1,1.6-1.9,2.4-2.9l7.6-8.9h5.7l-9.9,10.5l11.3,15.1h-5.7l-8.9-12.3l-2.4,2.6v9.7
		h-4.7v-37.6h4.7v23.8L163.4,308.4L163.4,308.4z"/>
	<path fill="#F4F4F4" d="M186.2,310.3c0.2,6.3,4.2,8.9,8.7,8.9c3.4,0,5.3-0.6,7.1-1.3l0.8,3.4c-1.6,0.8-4.5,1.6-8.6,1.6
		c-7.9,0-12.6-5.2-12.6-12.9c0-7.8,4.5-13.9,12-13.9c8.4,0,10.7,7.4,10.7,12.1c0,1-0.2,1.6-0.2,2.1L186.2,310.3L186.2,310.3z
		 M200,306.9c0-2.9-1.3-7.6-6.5-7.6c-4.7,0-6.8,4.4-7.1,7.6H200z"/>
	<path fill="#F4F4F4" d="M215.9,289.3v7.4h6.6v3.6h-6.6v13.9c0,3.2,1,5,3.6,5c1.3,0,2.1-0.2,2.8-0.3l0.2,3.6c-1,0.3-2.3,0.6-4.2,0.6
		c-2.1,0-3.9-0.6-5-1.9c-1.3-1.5-1.8-3.7-1.8-6.6v-14.1h-4v-3.6h4v-6.2L215.9,289.3z"/>
	<path fill="#F4F4F4" d="M233,289.4c0,1.6-1.1,2.9-2.9,2.9c-1.6,0-2.8-1.3-2.8-2.9s1.3-2.9,2.9-2.9C231.9,286.5,233,287.7,233,289.4z
		 M227.8,322.3v-25.7h4.7v25.7H227.8z"/>
	<path fill="#F4F4F4" d="M240.3,303.5c0-2.6,0-4.9-0.2-7h4.2l0.3,4.2h0.2c1.3-2.4,4.2-4.9,8.4-4.9c3.6,0,9.1,2.1,9.1,11v15.4h-4.7
		v-14.7c0-4.2-1.6-7.6-6-7.6c-3.1,0-5.5,2.1-6.3,4.9c-0.2,0.6-0.3,1.5-0.3,2.1v15.4h-4.7L240.3,303.5L240.3,303.5z"/>
	<path fill="#F4F4F4" d="M291.9,296.6c-0.2,1.8-0.2,3.9-0.2,7.1v14.9c0,5.8-1.1,9.5-3.7,11.7c-2.4,2.3-6.2,3.1-9.4,3.1
		c-3.1,0-6.5-0.8-8.6-2.1l1.1-3.6c1.6,1.1,4.4,1.9,7.6,1.9c4.9,0,8.3-2.4,8.3-8.9v-2.9h-0.2c-1.5,2.4-4.2,4.4-8.1,4.4
		c-6.3,0-11-5.3-11-12.5c0-8.7,5.7-13.6,11.5-13.6c4.5,0,7,2.3,8.1,4.5h0.2l0.2-3.9h4.2V296.6z M287.1,306.8c0-0.8,0-1.5-0.3-2.1
		c-0.8-2.8-3.1-4.9-6.5-4.9c-4.5,0-7.6,3.7-7.6,9.7c0,5,2.6,9.2,7.6,9.2c2.9,0,5.5-1.8,6.5-4.9c0.3-0.8,0.3-1.6,0.3-2.4V306.8z"/>
</g>
<g id="Through" opacity="0.7">
	<path fill="#F4F4F4" d="M316.2,289.3v7.4h6.6v3.6h-6.6v13.9c0,3.2,1,5,3.6,5c1.3,0,2.1-0.2,2.8-0.3l0.2,3.6c-1,0.3-2.3,0.6-4.2,0.6
		c-2.1,0-3.9-0.6-5-1.9c-1.3-1.5-1.8-3.7-1.8-6.6v-14.1h-4v-3.6h4v-6.2L316.2,289.3z"/>
	<path fill="#F4F4F4" d="M328.2,284.6h4.7v16h0.2c0.8-1.3,1.9-2.4,3.4-3.2c1.5-0.8,3.1-1.3,4.9-1.3c3.4,0,8.9,2.1,8.9,11v15.2h-4.7
		v-14.7c0-4.2-1.6-7.6-6-7.6c-3.1,0-5.3,2.1-6.3,4.7c-0.3,0.6-0.3,1.3-0.3,2.3v15.5h-4.7v-37.9H328.2z"/>
	<path fill="#F4F4F4" d="M357.7,304.7c0-3.1,0-5.7-0.2-8.1h4l0.2,5h0.2c1.1-3.4,4-5.7,7.1-5.7c0.5,0,1,0,1.3,0.2v4.4
		c-0.5-0.2-1-0.2-1.6-0.2c-3.2,0-5.7,2.4-6.3,6c-0.2,0.6-0.2,1.5-0.2,2.1v13.8h-4.7v-17.5L357.7,304.7L357.7,304.7z"/>
	<path fill="#F4F4F4" d="M397.8,309.2c0,9.5-6.6,13.6-12.8,13.6c-7,0-12.3-5-12.3-13.3c0-8.6,5.7-13.6,12.8-13.6
		C392.8,296.1,397.8,301.4,397.8,309.2z M377.4,309.5c0,5.7,3.2,9.9,7.8,9.9s7.8-4.2,7.8-10c0-4.4-2.1-9.9-7.8-9.9
		S377.4,304.7,377.4,309.5z"/>
	<path fill="#F4F4F4" d="M425.1,315.3c0,2.6,0,5,0.2,7h-4.2l-0.3-4.2h-0.2c-1.3,2.1-3.9,4.9-8.4,4.9c-4,0-8.9-2.3-8.9-11.3v-15.1h4.7
		v14.2c0,4.9,1.5,8.1,5.7,8.1c3.1,0,5.3-2.1,6.2-4.2c0.3-0.6,0.5-1.6,0.5-2.4v-15.7h4.7L425.1,315.3L425.1,315.3L425.1,315.3z"/>
	<path fill="#F4F4F4" d="M455.1,296.6c-0.2,1.8-0.2,3.9-0.2,7.1v14.9c0,5.8-1.1,9.5-3.7,11.7c-2.4,2.3-6.2,3.1-9.4,3.1
		c-3.1,0-6.5-0.8-8.6-2.1l1.1-3.6c1.6,1.1,4.4,1.9,7.6,1.9c4.9,0,8.3-2.4,8.3-8.9v-2.9H450c-1.5,2.4-4.2,4.4-8.1,4.4
		c-6.3,0-11-5.3-11-12.5c0-8.7,5.7-13.6,11.5-13.6c4.5,0,7,2.3,8.1,4.5h0.2l0.2-3.9h4.2L455.1,296.6L455.1,296.6z M450.2,306.8
		c0-0.8,0-1.5-0.3-2.1c-0.8-2.8-3.1-4.9-6.5-4.9c-4.5,0-7.6,3.7-7.6,9.7c0,5,2.6,9.2,7.6,9.2c2.9,0,5.5-1.8,6.5-4.9
		c0.3-0.8,0.3-1.6,0.3-2.4V306.8z"/>
	<path fill="#F4F4F4" d="M462.5,284.6h4.7v16h0.2c0.8-1.3,1.9-2.4,3.4-3.2s3.1-1.3,4.9-1.3c3.4,0,8.9,2.1,8.9,11v15.2h-4.7v-14.7
		c0-4.2-1.6-7.6-6-7.6c-3.1,0-5.3,2.1-6.3,4.7c-0.3,0.6-0.3,1.3-0.3,2.3v15.5h-4.7v-37.9H462.5z"/>
</g>
<g id="Gamification" opacity="0.7">
	<path fill="#F4F4F4" d="M530.7,320.7c-2.1,0.8-6.2,1.9-11,1.9c-5.3,0-9.9-1.5-13.4-4.7c-3.1-2.9-5-7.8-5-13.3
		c0-10.7,7.4-18.5,19.4-18.5c4.2,0,7.4,1,8.9,1.6l-1.1,3.7c-1.9-0.8-4.4-1.6-7.9-1.6c-8.7,0-14.4,5.3-14.4,14.4s5.5,14.4,13.8,14.4
		c3.1,0,5-0.5,6.2-1v-10.5h-7.3v-3.7h11.8v17.2l0,0L530.7,320.7L530.7,320.7z"/>
	<path fill="#F4F4F4" d="M552.2,322.3l-0.3-3.2h-0.2c-1.5,1.9-4.2,3.9-7.9,3.9c-5.2,0-7.9-3.7-7.9-7.4c0-6.2,5.5-9.5,15.4-9.5v-0.5
		c0-2.1-0.6-6-5.8-6c-2.4,0-4.9,0.8-6.6,1.9l-1.1-3.1c2.1-1.5,5.2-2.3,8.4-2.3c7.9,0,9.7,5.3,9.7,10.5v9.5c0,2.3,0.2,4.4,0.5,6.2
		H552.2z M551.6,309.2c-5-0.2-10.8,0.8-10.8,5.8c0,3.1,1.9,4.5,4.4,4.5c3.4,0,5.5-2.1,6.2-4.4c0.2-0.5,0.3-1,0.3-1.5v-4.4H551.6z"/>
	<path fill="#F4F4F4" d="M563.5,303.5c0-2.6,0-4.9-0.2-7h4l0.2,4.2h0.2c1.5-2.4,3.9-4.7,8.1-4.7c3.6,0,6.2,2.1,7.3,5.2h0.2
		c0.8-1.5,1.8-2.6,2.9-3.4c1.6-1.1,3.2-1.8,5.7-1.8c3.4,0,8.4,2.3,8.4,11.2v15.1h-4.5v-14.6c0-4.9-1.8-7.9-5.5-7.9
		c-2.6,0-4.7,1.9-5.5,4.2c-0.2,0.6-0.3,1.5-0.3,2.3v15.9H580v-15.4c0-4-1.8-7.1-5.3-7.1c-2.9,0-5,2.3-5.8,4.7
		c-0.3,0.6-0.3,1.5-0.3,2.3v15.5h-4.5v-18.6h-0.6L563.5,303.5L563.5,303.5z"/>
	<path fill="#F4F4F4" d="M613.1,289.4c0,1.6-1.1,2.9-2.9,2.9c-1.6,0-2.8-1.3-2.8-2.9s1.3-2.9,2.9-2.9S613.1,287.7,613.1,289.4z
		 M607.9,322.3v-25.7h4.7v25.7H607.9z"/>
	<path fill="#F4F4F4" d="M620.7,322.3v-22.2h-3.6v-3.6h3.6V295c0-6.8,4-11.2,11.2-11.2c2.4,0,5.2,0.8,6.5,1.6l-1.3,3.6
		c-1.1-0.8-3.1-1.5-5.3-1.5c-4.9,0-6.3,3.4-6.3,7.6v1.3h14.9v25.7h-4.5v-22.2h-10.4v22.2L620.7,322.3L620.7,322.3z"/>
	<path fill="#F4F4F4" d="M666.3,321.3c-1.3,0.6-3.9,1.5-7.4,1.5c-7.8,0-12.8-5.2-12.8-13.1s5.3-13.6,13.8-13.6c2.8,0,5.2,0.6,6.5,1.3
		l-1.1,3.6c-1.1-0.6-2.9-1.3-5.3-1.3c-5.8,0-9.1,4.4-9.1,9.7c0,6,3.9,9.5,8.9,9.5c2.6,0,4.4-0.6,5.7-1.3L666.3,321.3z"/>
	<path fill="#F4F4F4" d="M686.1,322.3l-0.3-3.2h-0.2c-1.5,1.9-4.2,3.9-7.9,3.9c-5.2,0-7.9-3.7-7.9-7.4c0-6.2,5.5-9.5,15.4-9.5v-0.5
		c0-2.1-0.6-6-5.8-6c-2.4,0-4.9,0.8-6.6,1.9l-1.1-3.1c2.1-1.5,5.2-2.3,8.4-2.3c7.9,0,9.7,5.3,9.7,10.5v9.5c0,2.3,0.2,4.4,0.5,6.2
		H686.1z M685.3,309.2c-5-0.2-10.8,0.8-10.8,5.8c0,3.1,1.9,4.5,4.4,4.5c3.4,0,5.5-2.1,6.2-4.4c0.2-0.5,0.3-1,0.3-1.5v-4.4H685.3z"/>
	<path fill="#F4F4F4" d="M702.7,289.3v7.4h6.6v3.6h-6.6v13.9c0,3.2,1,5,3.6,5c1.3,0,2.1-0.2,2.8-0.3l0.2,3.6c-1,0.3-2.3,0.6-4.2,0.6
		c-2.1,0-3.9-0.6-5-1.9c-1.3-1.5-1.8-3.7-1.8-6.6v-14.1h-4v-3.6h4v-6.2L702.7,289.3z"/>
	<path fill="#F4F4F4" d="M719.9,289.4c0,1.6-1.1,2.9-2.9,2.9c-1.6,0-2.8-1.3-2.8-2.9s1.3-2.9,2.9-2.9
		C718.8,286.5,719.9,287.7,719.9,289.4z M714.7,322.3v-25.7h4.7v25.7H714.7z"/>
	<path fill="#F4F4F4" d="M750.3,309.2c0,9.5-6.6,13.6-12.8,13.6c-7,0-12.3-5-12.3-13.3c0-8.6,5.7-13.6,12.8-13.6
		C745.5,296.1,750.3,301.4,750.3,309.2z M730.1,309.5c0,5.7,3.2,9.9,7.8,9.9s7.8-4.2,7.8-10c0-4.4-2.1-9.9-7.8-9.9
		S730.1,304.7,730.1,309.5z"/>
	<path fill="#F4F4F4" d="M756.3,303.5c0-2.6,0-4.9-0.2-7h4.2l0.3,4.2h0.2c1.3-2.4,4.2-4.9,8.4-4.9c3.6,0,9.1,2.1,9.1,11v15.4h-4.7
		v-14.7c0-4.2-1.6-7.6-6-7.6c-3.1,0-5.5,2.1-6.3,4.9c-0.2,0.6-0.3,1.5-0.3,2.1v15.4h-4.7V303.5L756.3,303.5z"/>
</g>
</svg>
                  </div>
          </div>
          <div class=" bottom-0 ">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 36.54" >
          <g id="Layer_1" data-name="Layer 1"/>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M0,36.54S82,.53,605,0s675,36.54,675,36.54H0Z" fill="#111827"/>
            </g>
          </g>
        </svg>
        </div>
        </div>

        <!-- What we do section -->
        <div class="relative h-full bg-gradient-to-b from-gray-900 via-blue-800 to-gray-900 sm:pt-16 lg:pt-8 pb-14 mx-auto max-w-7xl">

            <div class="text-center  max-w-md  sm:max-w-3xl px-8 lg:max-w-7xl  mx-auto">
              <h1 class="text-4xl font-extrabold tracking-tight text-gray-100 sm:text-5xl lg:text-6xl">
              Contact Us
            </h1>
            <p class="mt-5 max-w-prose mx-auto text-xl text-blue-300">
              Tokaly is a marketing platform which uses gamification for Brand & Product promotion and / or activations.
            </p>
            </div>
            <div class="max-w-7xl mx-auto pt-12 pb-5 px-4 sm:px-6 lg:pt-16 lg:px-8 text-center ">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3" >

                  <div class="flow-root bg-gray-200 rounded-lg p-6" id="gsapFooter">
                      <router-link :to="'/'" @click="scrollToAnchorPoint('top')" >
                      <div style="display: table-cell; text-align: center; vertical-align:middle;">
                        <img  src="..\assets\logos\DevInspire_Logo_dark.png" alt="Dev Inspire" />
                      </div>
                      </router-link>
                  </div>

                  <div class="flow-root bg-gray-200 rounded-lg px-6 pb-8; " style="text-align: center; " id="gsapFooter" >
                      <div class="relative text-md text-gray-700 font-medium mt-5 m-auto" style="width:230px;">
                        <div style="width: 100%; display: table;  ">
                            <div v-for="item in contact" :key="item.title" style="display: table-row;" >
                              <div style="display: table-cell; text-align:left;vertical-align:middle;" class="pb-3">
                                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                </div>
                              <div style="display: table-cell; text-align:left;vertical-align:middle; width:200px" class="pb-3">
                                <a :href="item.href">{{ item.description }}</a>
                              </div>
                            </div>
                        </div>
                      </div>
                  </div>

                  <div class="flow-root bg-gray-100 rounded-lg p-8" id="gsapFooter">
                     <div class="relative text-md text-gray-700 font-medium mt-2 m-auto" style="width:300px;">
                      <div class="flex space-x-6 md:order-2">
                        <a v-for="item in footerNavigation.social" :key="item.title" :href="item.href" class="text-gray-400 hover:text-gray-500">
                          <span class="sr-only">{{ item.title }}</span>
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </a>
                      </div>
                      <p class="relative text-md text-gray-500 font-medium mt-5 text-left">
                        &copy; {{this.currentDate}} Dev Inspire. All rights reserved.
                      </p>
                      <p class="relative text-md text-gray-500 font-medium mt-5 text-left">
                        <u><router-link to="/cookiePolicy">Cookie Policy</router-link></u> | <u><a href="PAIA_Manual.pdf" target="_blank">PAIA Manual</a></u>
                      </p>
                     </div>
                  </div>
              </div>
        </div>

        </div>

    </div>
  </div>
</template>

<script src=".\contactUs.js"></script>
