import { defineComponent, h } from 'vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import {
  ChatIcon,
  MenuIcon,
  QrcodeIcon,
  XIcon,
  UserGroupIcon,
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon
} from '@heroicons/vue/outline'
import { ChevronRightIcon, ExternalLinkIcon } from '@heroicons/vue/solid'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

// https://vue-hero-icons.netlify.app

const navigation = [
  // { name: 'Products', click: "scrollToAnchorPoint('productsBlock')" },
  { name: 'Contact Us', click: 'contact-us' }
]

const WhatWeDo = [
  {
    id: '1',
    title: 'Gamify questionnaires and surveys',
    description:
      'Gamification is the process of using game-like mechanics to incentivise behavior.'
  },
  {
    id: '2',
    title: 'Gamify lead forms',
    category: { name: '', href: '#' },
    description:
      'Gamify lead forms'
  },
  {
    id: '3',
    title: 'CRM',
    category: { name: '', href: '#' },
    description:
      'Tokaly is POPIA compliant for all generated leads'
  },
  {
    id: '4',
    title: 'Digital activations',
    category: { name: '', href: '#' },
    description:
      'Tokaly facilitates digital brand or product activations.'
  },
  {
    id: '5',
    title: 'Physical activations',
    category: { name: '', href: '#' },
    description:
      'Tokaly facilitates physical brand or product activations.'
  },
  {
    id: '6',
    title: 'Connect Web Hooks',
    category: { title: '', href: '#' },
    description:
      'Tokaly is flexible and allows for integration into 3rd party solutions.'
  },
  {
    id: '7',
    title: 'No-Code Workflows',
    category: { title: '', href: '#' },
    description:
      'Tokaly utilises no-code workflows.'
  },
  {
    id: '8',
    title: 'Receipt scanning',
    category: { title: '', href: '#' },
    description:
      ''
  },
  {
    id: '9',
    title: 'Digital sticker book',
    category: { title: '', href: '#' },
    description:
      ''
  }
]

const Channels = [
  {
    title: 'WhatsApp',
    description: 'Facilitation of onboarding your business to WhatsApp business and automations.',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d:
              'M23.33,9.2c-.79-3.89-3.56-7.12-7.32-8.52-.57-.21-1.5-.46-2.2-.58-.67-.11-2.61-.14-3.3-.04C5.47,.74,1.55,4.4,.59,9.32c-.47,2.46-.12,4.97,1.03,7.29l.42,.82-1.03,3.04c-.56,1.65-1.01,3.01-1.01,3.05,.04,0,1.46-.44,3.18-.98l3.17-1.01,.6,.29c2.47,1.16,5.22,1.44,7.83,.79,4.01-.99,7.24-4.11,8.35-8.06,.47-1.67,.54-3.66,.2-5.35Zm-1.91,4.6c-.81,3.19-3.22,5.78-6.36,6.81-2.68,.9-5.65,.59-8.03-.83-.22-.13-.42-.24-.44-.24-.01,0-.85,.27-1.86,.59-.97,.31-1.77,.54-1.83,.54,0-.02,.27-.83,.59-1.79l.6-1.74-.27-.4c-.33-.5-.87-1.59-1.07-2.17C1.19,10.04,3.25,5.03,7.59,2.84c1.78-.9,3.72-1.21,5.78-.94,3.78,.52,6.98,3.33,7.99,7.02,.43,1.57,.45,3.33,.06,4.88Z M17.5,15.72c-.45,.66-1.75,1.23-2.61,1.15-.63-.07-2.1-.56-3.06-1.01-1.23-.59-2.52-1.67-3.57-2.98-.6-.75-1.21-1.66-1.47-2.21-.35-.73-.48-1.3-.44-1.96,.01-.28,.06-.62,.1-.75,.21-.65,.77-1.42,1.17-1.62,.18-.09,.31-.11,.75-.11,.51,0,.54,0,.67,.14,.09,.09,.32,.59,.66,1.4,.29,.7,.52,1.29,.52,1.32,0,.14-.28,.6-.61,.98-.23,.27-.36,.47-.36,.54,0,.06,.16,.37,.37,.67,.5,.75,1.41,1.67,2.12,2.14,.56,.37,1.56,.85,1.76,.85,.15,0,.42-.27,.97-.96,.23-.28,.42-.49,.5-.51,.07-.02,.23,.01,.37,.06,.38,.15,2.32,1.1,2.42,1.19,.2,.18,.04,1.24-.26,1.67Z',
            'clip-rule': 'evenodd'
          })
        ])
    })
  },
  {
    title: 'Facebook',
    description: '',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M23.59,11.8c0,5.98-4.44,10.91-10.2,11.68V14.93h3.05l.48-3.1h-3.53v-1.69c0-1.29,.42-2.43,1.63-2.43h1.94v-2.71c-.34-.04-1.06-.14-2.42-.14-2.84,0-4.5,1.5-4.5,4.91v2.07h-2.92v3.1h2.92v8.52h0C4.35,22.61,0,17.71,0,11.8,0,5.28,5.28,0,11.8,0s11.8,5.28,11.8,11.8Z',
            'clip-rule': 'evenodd'
          })
        ])
    })
  },
  {
    title: 'Landing pages',
    description: '',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d:
              'M24.05,0L0,12.02l7.06,2.4,.45,8.12,3.76-4.21,3.76,5.71L24.05,0ZM11.27,15.63l6.46-8.87-9.32,6.46-4.51-1.5L21.34,3.01l-6.76,17.74-3.31-5.11Z',
            'clip-rule': 'evenodd'
          })
        ])
    })
  },
  {
    title: 'QR Codes',
    description: 'We follow best practices and guidelines to ensure that your information is secure.',
    icon: QrcodeIcon
  },
  {
    title: 'Tokaly client area',
    description:
        '.',
    icon: UserGroupIcon
  }
]

const contact = [
  {
    name: 'Phone',
    description: ' +27 (0)11 312 9409',
    icon: PhoneIcon
  },
  {
    name: 'E-Mail',
    description: 'info@devinspire.co.za',
    href: 'mailto:info@devinspire.co.za',
    icon: MailIcon
  },
  {
    title: 'Address',
    description: 'Midrand, South Africa',
    icon: LocationMarkerIcon
  }
]

const games = [
  {
    title: 'Darts',
    description: 'Exciting darts game',
    source: './images/darts.jpg'
  },
  {
    title: 'DiceAPrize',
    description: 'Roll and win',
    source: './images/DiceAPrize.jpg'
  },
  {
    title: 'Dynamite',
    description: 'Wild West blow it up game',
    source: './images/dynamite.jpg'
  },
  {
    title: 'Fishy',
    description: 'Go fish!',
    source: './images/fishy.jpg'
  },
  {
    title: 'goal',
    description: 'Kick and score',
    source: './images/goal.jpg'
  },
  {
    title: 'Level Up',
    description: 'Exciting darts game',
    source: './images/levelup.jpg'
  },
  {
    title: 'Spin the Wheel',
    description: 'Spin and WIN!',
    source: './images/spinthewheel.jpg'
  },
  {
    title: 'Pick-a-Box',
    description: 'Pick a box and WIN!',
    source: './images/pickabox.jpg'
  },
  {
    title: 'Prize Call',
    description: 'Call and win',
    source: './images/prizecall.jpg'
  },
  {
    title: 'Spell It',
    description: 'Spelling game',
    source: './images/spellit.jpg'
  },
  {
    title: 'TreasurePick',
    description: 'Pick a chest game',
    source: './images/TreasurePick.jpg'
  },
  {
    title: 'Vault',
    description: 'Find the combination to WIN!',
    source: './images/vault.jpg'
  }
  // More files...
]
const footerNavigation = {
  social: [
    {
      title: 'Facebook',
      href: 'https://www.facebook.com/TokalyGamification',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d:
                'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      title: 'Instagram',
      href: 'https://www.instagram.com/devinspiresa/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d:
                'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      title: 'Twitter',
      href: 'https://twitter.com/DevInspireSA',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d:
                'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84'
            })
          ])
      })
    }
  ]
}

export default {
  name: 'Home',
  data: function () {
    return {
      hash: this.$route.hash
    }
  },
  mounted: function () {
    window.scrollTo(0, 0)

    const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } })// ease: Tween.Back.easeOut.config(2),

    tl.from('#Marketing', { opacity: 0, delay: 1, scale: 0.9, transformOrigin: 'center', ease: 'circ', duration: 0.5 }, '-=.2')
    tl.from('#Through', { opacity: 0, delay: 0, scale: 0.9, transformOrigin: 'center', ease: 'circ', duration: 0.5 }, '-=.2')
    tl.from('#Gamification', { opacity: 0, delay: 0, scale: 0.9, transformOrigin: 'center', ease: 'circ', duration: 0.5 }, '-=.2')
    tl.to('#Marketing', { opacity: 0.7, duration: 0.5 }, '-=.2')
    tl.to('#Through', { opacity: 0.7, duration: 0.5 }, '-=.2')
    tl.to('#Gamification', { opacity: 0.7, duration: 0.5 }, '-=.2')

    tl.from('#PolygonYellow', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#PolygonPink', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')
    tl.from('#PolygonBlue', { delay: 0, scale: 0.7, transformOrigin: 'center', rotate: 10 }, '-=.2')

    tl.from('#TokalyText', { delay: 0, scale: 0.9, transformOrigin: 'center', ease: 'circ', duration: 1.5 }, '-=.2')
    tl.to('#TokalyText', { opacity: 100, duration: 5 })

    tl.yoyo(true).repeat(-1).play(1.5)

    gsap.from('#gsapHeaderImage', { x: 50, duration: 2 })
    gsap.from('#gsapDev', { stagger: { amount: 1, from: 'left', grid: 'auto' }, opacity: 0, duration: 2 })

    gsap.from('#gsapSpDev', { scrollTrigger: { trigger: '#gsapSpDev', start: 'top bottom', end: 'bottom bottom', toggleActions: 'play none none none' }, stagger: { amount: 1, from: 'center', grid: 'auto' }, y: -200, opacity: 0, duration: 1 })
    gsap.from('#gsapFooter', { scrollTrigger: { trigger: '#gsapFooter', start: 'top bottom', end: 'bottom bottom', toggleActions: 'play none reverse none' }, stagger: { amount: 1, from: 'left' }, y: -200, opacity: 0, duration: 1 })

    this.$nextTick(function () {
      if (this.hash) {
        const refName = this.hash.replace('#', '')
        this.scrollToAnchorPoint(refName)
      }
    })
  },
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronRightIcon,
    ExternalLinkIcon,
    MenuIcon,
    XIcon,
    PhoneIcon,
    MailIcon,
    ChatIcon,
    LocationMarkerIcon
  },
  setup () {
    return {
      navigation,
      WhatWeDo,
      Channels,
      games,
      contact,
      footerNavigation
    }
  }

}
